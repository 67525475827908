import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import {
  ServiceShortEnum,
  ServiceTypeLabels,
  IntegrationStatusLabels,
  BillingStatusLabels,
} from 'utils/enums';

import Autocomplete from 'shared/components/forms/Autocomplete';
import DateTimeFilter from 'features/consults/components/ConsultFilters/DateTimeFilter';
import { FilterElement } from 'features/admin/pages/Consults/ConsultList/ConsultListFilters/ConsultFilters.style';
import SearchField from 'shared/components/forms/SearchField/SearchField';
import { FlexBoxAlign, FlexBoxColumn } from 'styles/layout';
import HealthSystemSelect from 'shared/components/data/forms/HealthSystemSelect';
import FacilitySelect from 'shared/components/data/forms/FacilitySelect';

export const filterFields = {
  fromDate: {
    name: 'fromCreatedAt',
    placeholder: 'From',
  },
  toDate: {
    name: 'toCreatedAt',
    placeholder: 'To',
  },
  consultType: {
    name: 'consultType',
    placeholder: 'Service Type',
  },
  integrationStatus: {
    name: 'integrationStatusList',
    placeholder: 'Integration Status',
  },
  billingStatus: {
    name: 'billingStatus',
    placeholder: 'Billing Status',
  },
  healthcare: {
    name: 'healthcareSystemId',
    placeholder: 'Healthcare System',
  },
  facility: {
    name: 'facilityId',
    placeholder: 'Facility',
  },
};

const BillingFilters = ({ filters, handleFilter }) => {
  const { control, register, watch } = useForm({ defaultValues: filters });
  return (
    <FlexBoxColumn>
      <FilterElement>
        <SearchField
          {...register('query')}
          onChange={(query) => handleFilter({ query })}
        />
      </FilterElement>
      <FlexBoxAlign flexWrap="wrap">
        <FilterElement>
          <Autocomplete
            {...filterFields.consultType}
            control={control}
            options={Object.entries(ServiceShortEnum).map(([key, value]) => ({
              id: value,
              name: ServiceTypeLabels[key],
            }))}
            handleChange={(value) =>
              handleFilter({ [filterFields.consultType.name]: value })
            }
          />
        </FilterElement>
        <FilterElement>
          <DateTimeFilter
            {...filterFields.fromDate}
            control={control}
            handleFilter={handleFilter}
          />
        </FilterElement>
        <FilterElement>
          <DateTimeFilter
            {...filterFields.toDate}
            control={control}
            handleFilter={handleFilter}
          />
        </FilterElement>
        <FilterElement>
          <Autocomplete
            {...filterFields.integrationStatus}
            control={control}
            options={Object.entries(IntegrationStatusLabels).map(
              ([id, name]) => ({ id, name }),
            )}
            handleChange={(value) =>
              handleFilter({ [filterFields.integrationStatus.name]: value })
            }
          />
        </FilterElement>
        <FilterElement>
          <Autocomplete
            {...filterFields.billingStatus}
            control={control}
            options={Object.entries(BillingStatusLabels).map(([id, name]) => ({
              id,
              name,
            }))}
            handleChange={(value) =>
              handleFilter({ [filterFields.billingStatus.name]: value })
            }
          />
        </FilterElement>
        <FilterElement>
          <HealthSystemSelect
            {...filterFields.healthcare}
            control={control}
            handleChange={(value) =>
              handleFilter({
                [filterFields.healthcare.name]: value,
                [filterFields.facility.name]: null,
              })
            }
          />
        </FilterElement>
        <FilterElement>
          <FacilitySelect
            {...filterFields.facility}
            control={control}
            healthSystem={watch(filterFields.healthcare.name)}
            requiredSystem
            handleChange={(value) =>
              handleFilter({ [filterFields.facility.name]: value })
            }
          />
        </FilterElement>
      </FlexBoxAlign>
    </FlexBoxColumn>
  );
};

BillingFilters.propTypes = {
  filters: PropTypes.shape({}).isRequired,
  handleFilter: PropTypes.func.isRequired,
};

export default BillingFilters;
