import { ServiceTypeEnum } from 'utils/enums';
import {
  mapChipFieldOptions,
  mapToggleFieldOptions,
  getOptions4Type,
} from 'features/consults/utils/formFields';
import fields from 'features/consults/shared/tabs/Plan/formFields';

export const leftPanel = {
  elements: [
    fields.recommendations,
    mapChipFieldOptions(fields.bloodPressureManagementPredefined),
    fields.bloodPressureManagementFreeText,
    mapToggleFieldOptions(fields.targetBloodPressureBelowPredefined),
    mapToggleFieldOptions(fields.targetBloodPressureAbovePredefined),
    mapToggleFieldOptions(fields.targetBloodPressureDiastolicPredefined),
    fields.targetBloodPressureFreeText,
  ],
};

export const rightPanel = {
  elements: [
    mapChipFieldOptions(
      getOptions4Type(fields.labsPredefined, ServiceTypeEnum.TELE_NEURO),
    ),
    fields.labsFreeText,
    mapChipFieldOptions(fields.imagingUrgencySTATPredefined),
    fields.imagingUrgencySTATFreeText,
    mapChipFieldOptions(
      getOptions4Type(
        fields.imagingUrgencyRoutinePredefined,
        ServiceTypeEnum.TELE_NEURO,
      ),
    ),
    fields.imagingUrgencyRoutineFreeText,
    mapChipFieldOptions(
      getOptions4Type(
        fields.diagnosticTestPredefined,
        ServiceTypeEnum.TELE_NEURO,
      ),
    ),
    fields.diagnosticTestFreeText,
    mapChipFieldOptions(
      getOptions4Type(
        fields.therapyEvaluationPredefined,
        ServiceTypeEnum.TELE_NEURO,
      ),
    ),
    fields.therapyEvaluationFreeText,
    mapChipFieldOptions(
      getOptions4Type(fields.medicationsPredefined, ServiceTypeEnum.TELE_NEURO),
    ),
    fields.medicationsFreeText,
    mapChipFieldOptions(
      getOptions4Type(
        fields.vteProphylaxisPredefined,
        ServiceTypeEnum.TELE_NEURO,
      ),
    ),
    fields.vteProphylaxisFreeText,
    mapChipFieldOptions(fields.hemorrhageReversalPredefined),
    fields.hemorrhageReversalFreeText,
    mapChipFieldOptions(
      getOptions4Type(fields.otherPredefined, ServiceTypeEnum.TELE_NEURO),
    ),
    fields.otherFreeText,
    mapToggleFieldOptions(fields.codeAdm),
    fields.codeAdmFreeText,
    fields.additionalRecommendations,
    mapToggleFieldOptions(
      getOptions4Type(fields.dispositionPredefined, ServiceTypeEnum.TELE_NEURO),
    ),
    fields.dispositionFreeText,
  ],
};
