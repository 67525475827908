import { ServiceTypeEnum, ConsultFieldTypeEnum } from 'utils/enums';

const hpiFields = {
  chiefComplaintPredefined: {
    name: 'chiefComplaintPredefined',
    label: 'Chief Complaint',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'АbnormalМovements', label: 'abnormal movements' },
      { value: 'Ams', label: 'ams', summaryLabel: 'altered mental state' },
      { value: 'Confusion', label: 'confusion' },
      { value: 'Dizziness', label: 'dizziness' },
      { value: 'VisionChanges', label: 'vision changes' },
      { value: 'SpeechChanges', label: 'speech changes' },
      { value: 'Headache', label: 'headache' },
      { value: 'Numbness', label: 'numbness' },
      { value: 'Seizure', label: 'seizure' },
      { value: 'StrokeSubacute', label: 'stroke (subacute)' },
      { value: 'WeaknessFocal', label: 'weakness (focal)' },
      { value: 'WeaknessGeneralized', label: 'weakness (generalized)' },
    ],
  },
  chiefComplaintFreeText: {
    name: 'chiefComplaintFreeText',
    associatedField: 'chiefComplaintPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  handedness: {
    name: 'handedness',
    title: 'Handedness',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Right', label: 'Right' },
      { value: 'Left', label: 'Left' },
    ],
  },
  additionalHistory: {
    name: 'additionalHistory',
    label: 'History (Freetext)',
    summaryLabel: 'History',
    type: ConsultFieldTypeEnum.DICTATION,
    multiline: true,
    rows: 5,
  },
  lastKnownNormalPredefined: {
    name: 'lastKnownNormalPredefined',
    label: 'Last Known Normal',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'LKNBestAvailable',
        label: 'LKN best available',
        summaryLabel:
          'I have collected independent history specific to time last normal or last known well. We have collaborated with the provider and at this time, we have the most current timeline with the information that is available.',
      },
    ],
  },
  lastKnownNormalFreeText: {
    name: 'lastKnownNormalFreeText',
    associatedField: 'lastKnownNormalPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  durationConditionPredefined: {
    name: 'durationConditionPredefined',
    label: 'Duration',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'Constant', label: 'constant' },
      { value: 'Intermittent', label: 'intermittent' },
      { value: 'Improving', label: 'improving' },
      { value: 'Stable', label: 'stable' },
      { value: 'WaxWane', label: 'wax/wane' },
      { value: 'Resolved', label: 'resolved' },
    ],
  },
  durationPeriodPredefined: {
    name: 'durationPeriodPredefined',
    associatedField: 'durationConditionPredefined',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'Seconds', label: 'seconds' },
      { value: 'Minutes', label: 'minutes' },
      { value: 'Hours', label: 'hours' },
      { value: 'Days', label: 'days' },
      { value: 'Weeks', label: 'weeks' },
      { value: 'Months', label: 'months' },
    ],
  },
  durationConditionFreeText: {
    name: 'durationConditionFreeText',
    associatedField: 'durationConditionPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  durationPeriodFreeText: {
    name: 'durationPeriodFreeText',
    associatedField: 'durationConditionPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  possibleAlteplaseCandidatePredefined: {
    name: 'possibleAlteplaseCandidatePredefined',
    label: 'Possible Thrombolytic candidate',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      {
        value: 'NotOnWarfarinOrNOACs',
        label: 'not on warfarin or NOACs',
        summaryLabels: {
          negative: 'on warfarin or NOAC',
        },
      },
      {
        value: 'NoICHhx',
        label: 'no ICH hx',
        summaryLabels: {
          positive: 'no intracranial hemorrhage history',
          negative: 'history of ICH',
        },
      },
      {
        value: 'NoRecentSurg',
        label: 'no recent surg',
        summaryLabels: {
          positive: 'no recent major surgery',
          negative: 'recent surgery',
        },
      },
      {
        value: 'NoActiveMajorIntBleed',
        label: 'no active major int. bleed',
        summaryLabels: {
          positive: 'no known active major internal bleeding',
          negative: 'active internal bleeding',
        },
      },
      {
        value: 'NoKnownBloodDisorders',
        label: 'no known blood disorders',
        summaryLabels: {
          negative: 'known blood disorder',
        },
      },
      {
        value: 'NoStrokeInLast3Months',
        label: 'no stroke in last 3 months',
      },
    ],
  },
  possibleAlteplaseCandidateFreeText: {
    name: 'possibleAlteplaseCandidateFreeText',
    associatedField: 'possibleAlteplaseCandidatePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  associatedSymptomsPredefined: {
    name: 'associatedSymptomsPredefined',
    label: 'Associated Symptoms',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      { value: 'Confusion', label: 'confusion' },
      { value: 'Dizziness', label: 'dizziness' },
      { value: 'DoubleVision', label: 'double vision' },
      { value: 'Falls', label: 'falls' },
      { value: 'GaitDyscoordination', label: 'gait dyscoordination' },
      { value: 'Headache', label: 'headache' },
      { value: 'HeadTrauma', label: 'head trauma' },
      {
        value: 'NewHoarseness',
        label: 'new hoarseness',
        summaryLabel: 'new hoarseness of voice',
      },
      { value: 'LightSensitivity', label: 'light sensitivity' },
      { value: 'Nausea', label: 'nausea' },
      { value: 'NeckPain', label: 'neck pain' },
      { value: 'SoundSensitivity', label: 'sound sensitivity' },
      { value: 'SwallowingProblems', label: 'swallowing problems' },
      { value: 'Syncope', label: 'syncope' },
      { value: 'VisionChanges', label: 'vision changes' },
      { value: 'Vomiting', label: 'vomiting' },
    ],
  },
  associatedSymptomsFreeText: {
    name: 'associatedSymptomsFreeText',
    associatedField: 'associatedSymptomsPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  qualityPredefined: {
    name: 'qualityPredefined',
    label: 'Quality',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      { value: 'Numbness', label: 'numbness' },
      { value: 'Paralysis', label: 'paralysis' },
      { value: 'PinsAndNeedles', label: 'pins and needles' },
      { value: 'SlurredSpeech', label: 'slurred speech' },
      { value: 'Tingling', label: 'tingling' },
      { value: 'Weakness', label: 'weakness' },
      { value: 'WordFindingDifficulty', label: 'word finding difficulty' },
    ],
  },
  qualityFreeText: {
    name: 'qualityFreeText',
    associatedField: 'qualityPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  contextPreExistingConditionsPredefined: {
    name: 'contextPreExistingConditionsPredefined',
    label: 'Context/Pre-existing conditions',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'ChronicDailyHeadache',
        label: 'chronic daily headache',
        showOn: [ServiceTypeEnum.CLINIC],
      },
      {
        value: 'ClusterHeadache',
        label: 'cluster headache',
        showOn: [ServiceTypeEnum.CLINIC],
      },
      {
        value: 'Concussion',
        label: 'concussion',
        showOn: [ServiceTypeEnum.CLINIC],
      },
      {
        value: 'GiantCellArteritis',
        label: 'giant cell arteritis',
        showOn: [ServiceTypeEnum.CLINIC],
      },
      {
        value: 'Headache',
        label: 'headache',
        showOn: [ServiceTypeEnum.CLINIC],
      },
      {
        value: 'Migraine',
        label: 'migraine',
        showOn: [ServiceTypeEnum.CLINIC],
      },
      {
        value: 'MigraineWithAura',
        label: 'migraine with aura',
        showOn: [ServiceTypeEnum.CLINIC],
      },
      {
        value: 'MigraineWithoutAura',
        label: 'migraine without aura',
        showOn: [ServiceTypeEnum.CLINIC],
      },
      {
        value: 'PreExistingMotorDeficit',
        label: 'pre-existing motor deficit',
      },
      {
        value: 'PreExistingSensoryDeficit',
        label: 'pre-existing sensory deficit',
      },
      {
        value: 'PreExistingSpeechProblem',
        label: 'pre-existing speech problem',
      },
      {
        value: 'PreExistingVisualProblem',
        label: 'pre-existing visual problem',
      },
      {
        value: 'PreExistingAtaxia',
        label: 'pre-existing ataxia',
      },
      {
        value: 'TBI',
        label: 'TBI',
        summaryLabel: 'TBI (traumatic brain injury)',
      },
      {
        value: 'Seizure',
        label: 'seizure',
        summaryLabel: 'seizure disorder',
      },
      {
        value: 'TensionHeadache',
        label: 'tension headache',
        showOn: [ServiceTypeEnum.CLINIC],
      },
      {
        value: 'TMJ',
        label: 'TMJ',
        showOn: [ServiceTypeEnum.CLINIC],
      },
      {
        value: 'TrigeminalNeuralgia',
        label: 'trigeminal neuralgia',
        showOn: [ServiceTypeEnum.CLINIC],
      },
    ],
  },
  contextPreExistingConditionsFreeText: {
    name: 'contextPreExistingConditionsFreeText',
    associatedField: 'contextPreExistingConditionsPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  severityPredefined: {
    name: 'severityPredefined',
    title: 'Severity',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Mild', label: 'Mild' },
      { value: 'Moderate', label: 'Moderate' },
      { value: 'Severe', label: 'Severe' },
    ],
  },
  severityFreeText: {
    name: 'severityFreeText',
    associatedField: 'severityPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  hospitalCourse: {
    name: 'hospitalCourse',
    label: 'Hospital Course',
    type: ConsultFieldTypeEnum.DICTATION,
    multiline: true,
    rows: 5,
  },
};

export default hpiFields;
