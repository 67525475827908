import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import debounce from 'lodash/debounce';

import DatePicker from 'react-datepicker';
import { AccessTime } from '@mui/icons-material';
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import styled from '@emotion/styled';

import ErrorMsg from 'shared/components/feedback/ErrorMsg/ErrorMsg';
import IconButton from 'shared/components/buttons/IconButton/IconButton';
import InputLabel from 'shared/components/forms/InputLabel/InputLabel';
import { FlexBoxAlign, FlexBoxWrap } from 'styles/layout';
import { DEFAULT_FIELD_FORMAT, TIME_FORMAT, formatDate } from 'utils/dates';
import { TIMEZONE_LABELS, timezoneStrings } from 'utils/timezones';
import { ConsultFieldTypeEnum } from 'utils/enums';
import { DEBOUNCE_TIMEOUT } from 'utils/constants';

import 'react-datepicker/dist/react-datepicker.css';

const StyledDateTime = styled(DateTimeField)`
  & input {
    padding: 9px 0 9px 14px;
  }
`;

const selectedDate = (date, timezone) => {
  if (!date) return null;

  return timezone
    ? utcToZonedTime(date, timezoneStrings[timezone])
    : new Date(date);
};

const CustomInput = React.forwardRef(
  ({ name, format, value, onChange, ...inputProps }, ref) => {
    const handleChange = debounce(onChange, DEBOUNCE_TIMEOUT);
    return (
      <StyledDateTime
        {...inputProps}
        inputRef={ref}
        format={format}
        value={value ? new Date(value) : null}
        onChange={(newValue, args) => {
          if (args?.validationError) return;
          handleChange({
            target: {
              name,
              value: newValue ? formatDate(newValue, format) : '',
            },
          });
        }}
      />
    );
  },
);

CustomInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  format: PropTypes.string,
  onChange: PropTypes.func,
};

const DateTimeInput = ({
  control,
  name,
  label,
  required = false,
  disabled = false,
  format = DEFAULT_FIELD_FORMAT,
  placeholder = null,
  disableFuture = false,
  onChange,
  timezone,
  error,
  inputStyle = null,
}) => {
  const inputPlaceholder = placeholder || format.toLowerCase();

  const timezoneLabelExtra = useMemo(
    () => (timezone ? ` (${TIMEZONE_LABELS[timezone]})` : ''),
    [timezone],
  );

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={null}
      render={({ field }) => (
        <FlexBoxWrap>
          {label && (
            <FlexBoxAlign>
              <InputLabel
                htmlFor={name}
                required={required}
                disabled={disabled}
                inline
                error={!!error}
              >
                {`${label}${timezoneLabelExtra}`}
              </InputLabel>
              <IconButton
                disabled={disabled}
                onClick={() => {
                  field.onChange(new Date());
                  onChange(name, ConsultFieldTypeEnum.DATETIME);
                }}
              >
                <AccessTime />
              </IconButton>
            </FlexBoxAlign>
          )}
          <FlexBoxAlign>
            <DatePicker
              name={name}
              selected={selectedDate(field.value, timezone)}
              dateFormat={format}
              timeIntervals={1}
              customInput={
                <CustomInput
                  style={inputStyle ? { ...inputStyle } : undefined}
                  error={!!error}
                  name={name}
                  format={format}
                />
              }
              isClearable
              timeFormat={TIME_FORMAT}
              placeholderText={inputPlaceholder}
              showTimeSelect
              onChange={(date, args) => {
                if (args?.validationError) return;
                const newDate =
                  timezone && date
                    ? zonedTimeToUtc(date, timezoneStrings[timezone])
                    : date;
                field.onChange(newDate);
                onChange(name, ConsultFieldTypeEnum.DATETIME);
              }}
              maxDate={disableFuture ? new Date() : null}
              disabled={disabled}
            />
          </FlexBoxAlign>
          {error && <ErrorMsg text={error.message} dense />}
        </FlexBoxWrap>
      )}
    />
  );
};

DateTimeInput.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  placeholder: PropTypes.string,
  timezone: PropTypes.string,
  disableFuture: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  inputStyle: PropTypes.shape({}),
};

export default DateTimeInput;
