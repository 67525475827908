import React from 'react';
import PropTypes from 'prop-types';

import HealthSystemSelect from 'shared/components/data/forms/HealthSystemSelect';
import FacilitySelect from 'shared/components/data/forms/FacilitySelect';
import ProviderSelect from 'shared/components/data/forms/ProviderSelect';
import NeurologistSelect from 'features/admin/components/ConsultsForm/NeurologistSelect';
import NetworkSelect from 'shared/components/data/forms/NetworkSelect';

import DateTimeFilter from 'features/consults/components/ConsultFilters/DateTimeFilter';

import Select from 'shared/components/forms/Select/ControlledSelect';
import Autocomplete from 'shared/components/forms/Autocomplete';

import { filterTypes } from './data';

const ConsultServiceFilter = ({
  filter: { filterType, ...filterProps },
  onFilter,
  consultType,
  sourcePage = '',
  formProps: { control, watch },
}) => {
  switch (filterType) {
    case filterTypes.DATETIME:
      return (
        <DateTimeFilter
          {...filterProps}
          control={control}
          handleFilter={onFilter}
        />
      );
    case filterTypes.SELECT:
      return (
        <Select
          {...filterProps}
          control={control}
          handleChange={({ target }) => {
            onFilter({ [target.name]: target.value });
          }}
        />
      );
    case filterTypes.MULTISELECT: {
      return (
        <Autocomplete
          {...filterProps}
          control={control}
          multiple
          handleChange={(values) => {
            onFilter({ [filterProps.name]: values });
          }}
        />
      );
    }

    case filterTypes.PROVIDER:
      if (sourcePage && sourcePage === 'Admin')
        return (
          <NeurologistSelect
            {...filterProps}
            control={control}
            consultType={consultType}
            displayUnassigned
            handleChange={(value) => {
              onFilter({ [filterProps.name]: value });
            }}
          />
        );
      return (
        <ProviderSelect
          {...filterProps}
          control={control}
          consultType={consultType}
          handleChange={(value) => {
            onFilter({ [filterProps.name]: value });
          }}
        />
      );
    case filterTypes.HEALTHSYSTEM:
      return (
        <HealthSystemSelect
          {...filterProps}
          control={control}
          handleChange={(value) => {
            onFilter({
              [filterProps.name]: value,
              facilityId: null,
            });
          }}
        />
      );
    case filterTypes.FACILITY:
      return (
        <FacilitySelect
          {...filterProps}
          networkType={consultType}
          control={control}
          requiredSystem
          healthSystem={watch('healthcareSystemId')}
          handleChange={(value) => {
            onFilter({ [filterProps.name]: value });
          }}
        />
      );
    case filterTypes.NETWORK:
      return (
        <NetworkSelect
          {...filterProps}
          control={control}
          handleChange={(value) => {
            onFilter({ [filterProps.name]: value });
          }}
        />
      );

    default:
      return <div>Unknow Filter</div>;
  }
};

ConsultServiceFilter.propTypes = {
  formProps: PropTypes.shape({
    control: PropTypes.shape({}),
    watch: PropTypes.func,
  }).isRequired,
  onFilter: PropTypes.func.isRequired,
  consultType: PropTypes.string.isRequired,
  sourcePage: PropTypes.string,
  filter: PropTypes.shape({
    filterType: PropTypes.string,
  }).isRequired,
};

export default ConsultServiceFilter;
