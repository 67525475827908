import React from 'react';
import { useParams } from 'react-router-dom';

import api from 'api';
import { useMountEffect, useFeedback, useEditMode } from 'shared/hooks';
import useGoBack from 'navigation/hooks/useGoBack';

import BackButton from 'navigation/components/BackButton/BackButton';
import AddButton from 'navigation/components/AddButton';
import EditButton from 'shared/components/buttons/EditButton';
import HealthSystemForm from 'features/admin/components/HealthSystemForm/HealthSystemForm';
import FacilitiesTable from 'features/admin/components/FacilitiesTable/FacilitiesTable';
import LoadingSpinner from 'shared/components/feedback/LoadingSpinner/LoadingSpinner';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';

import {
  Box,
  FlexBoxAlign,
  FlexBoxColumn,
  FlexBoxColumnFull,
  FlexBoxSpaceBetweenCenter,
} from 'styles/layout';

const HealthSystemDetailsPage = () => {
  const { locationId } = useParams();
  const [system, setSystem] = React.useState(null);
  const { isEditMode, enableEditMode, disableEditMode } = useEditMode();
  const { goBack } = useGoBack();
  const { clearDisplay, errorDisplay } = useFeedback();

  useMountEffect(() => {
    api.getHealthSystemById(locationId).then(({ data }) => {
      setSystem(data.healthSystem);
    });
  });

  const onEditSystem = (values) => {
    clearDisplay();
    api
      .updateHealthSystem({
        ...values,
        id: Number(locationId),
      })
      .then(({ data }) => {
        setSystem(data);
        disableEditMode();
      })
      .catch(() => errorDisplay('Error updating health system'));
  };

  if (!system) return <LoadingSpinner />;
  return (
    <FlexBoxColumn height="100%">
      <FlexBoxSpaceBetweenCenter sx={{ zIndex: 5 }}>
        <FlexBoxAlign>
          {!isEditMode && <BackButton onBack={() => goBack()} />}
          <PageTitle>{system.name}</PageTitle>
        </FlexBoxAlign>
        {!isEditMode && <EditButton onClick={enableEditMode} />}
      </FlexBoxSpaceBetweenCenter>
      <FlexBoxColumnFull>
        <HealthSystemForm
          disabled={!isEditMode}
          onSave={onEditSystem}
          onCancel={disableEditMode}
          initValues={system}
          buttonsPosition="left"
        />
        <Box mb={2} align="right">
          <AddButton to="add-facility">Add Facility</AddButton>
        </Box>
        <FacilitiesTable healthSystemId={locationId} />
      </FlexBoxColumnFull>
    </FlexBoxColumn>
  );
};

export default HealthSystemDetailsPage;
