import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import debounce from 'lodash/debounce';

import InputField from 'shared/components/forms/InputField/InputField';
import { ConsultFieldTypeEnum } from 'utils/enums';
import { FlexBoxColumn } from 'styles/layout';
import { DEBOUNCE_TIMEOUT } from 'utils/constants';

import DictationControls from './DictationControls';

const DictationInput = ({
  name,
  control,
  onChange,
  disabled,
  ...moreProps
}) => {
  const handleChange = debounce(() => {
    onChange(name, ConsultFieldTypeEnum.DICTATION);
  }, DEBOUNCE_TIMEOUT);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field }) => (
        <FlexBoxColumn>
          <InputField
            {...field}
            {...moreProps}
            value={field.value || ''}
            disabled={disabled}
            onChange={({ target }) => {
              field.onChange(target.value);
              handleChange();
            }}
          />
          <DictationControls
            id={name}
            disabled={disabled}
            onChange={(dictation) => {
              field.onChange(
                field.value ? `${field.value} ${dictation}` : `${dictation}`,
              );
              handleChange();
            }}
          />
        </FlexBoxColumn>
      )}
    />
  );
};

DictationInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default DictationInput;
