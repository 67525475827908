import React from 'react';
import { useParams } from 'react-router-dom';

import { Stack, Typography } from '@mui/material';

import api from 'api';
import { useMountEffect, useFeedback, useRequest } from 'shared/hooks';
import useGoBack from 'navigation/hooks/useGoBack';
import { formatedDateStr } from 'utils/dates';
import { MatchTypeLabels } from 'utils/enums';

import BackButton from 'navigation/components/BackButton/BackButton';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';

import { FlexBoxColumn, FlexBoxAlign } from 'styles/layout';

import OrderMatch from './OrderMatch';
import OrderUnmatch from './OrderUnmatch';

const orderDetails = [
  {
    label: 'Match Method',
    renderValue: ({ matchType }) => MatchTypeLabels[matchType],
  },
  {
    label: 'Order Received',
    renderValue: ({ createdAt }) => formatedDateStr(createdAt),
  },
  {
    label: 'First Name',
    renderValue: ({ givenName }) => givenName,
  },
  {
    label: 'Last Name',
    renderValue: ({ familyName }) => familyName,
  },
  {
    label: 'DOB',
    renderValue: ({ dob }) => formatedDateStr(dob),
  },
];

const OrderDetailsPage = () => {
  const { orderId } = useParams();
  const { errorDisplay } = useFeedback();
  const { goBack } = useGoBack();

  const { data: orderData, doRequest: getOrder } = useRequest(
    api.getAdminOrderDetails,
  );

  const fetchOrder = React.useCallback(() => {
    getOrder(orderId);
  }, [orderId, getOrder]);

  useMountEffect(fetchOrder);

  const onMatch = React.useCallback(
    async (consultId) => {
      try {
        await api.linkOrder({ consultId, hl7OrderId: orderId });
        fetchOrder();
      } catch (error) {
        errorDisplay('Error linking order');
      }
    },
    [errorDisplay, fetchOrder, orderId],
  );

  const onUnmatch = React.useCallback(
    async (consultId) => {
      try {
        await api.unlinkOrder({ consultId });
        fetchOrder();
      } catch (error) {
        errorDisplay('Error unlinking order');
      }
    },
    [errorDisplay, fetchOrder],
  );

  return (
    <FlexBoxColumn height="100%">
      <FlexBoxAlign>
        <BackButton onBack={goBack} />
        <PageTitle>Order Details</PageTitle>
      </FlexBoxAlign>
      {!!orderData && (
        <>
          <Stack direction="row" spacing={5} p={2} bgcolor="grey.200">
            {orderDetails.map(({ label, renderValue }) => (
              <Stack key={label}>
                <Typography variant="subtitle2">{label}:</Typography>
                <Typography variant="body2">
                  {renderValue(orderData)}
                </Typography>
              </Stack>
            ))}
          </Stack>
          <FlexBoxColumn mt={2}>
            {orderData.matchedStatus && orderData?.consultIds?.length > 0 ? (
              orderData.consultIds.map((item) => (
                <FlexBoxColumn mt={2}><OrderUnmatch key={item} consultId={item} onUnmatch={onUnmatch} /></FlexBoxColumn>
              ))
            ) : (
              <OrderMatch order={orderData} onMatch={onMatch} />
            )}
          </FlexBoxColumn>
        </>
      )}
    </FlexBoxColumn>
  );
};

export default OrderDetailsPage;
