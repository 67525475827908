import React from 'react';
import { Grid } from '@mui/material';

import { useFormState } from 'shared/hooks';
import DateTimeInput from 'features/consults/components/tabFields/DateTimeInput';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';
import fields from 'features/consults/pages/neuro/tabs/ScoresNeuro/formFields';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import ScoreGroup from 'features/consults/shared/tabs/Scores/ScoreGroup';
import SetZeroButton from 'features/consults/shared/tabs/Scores/SetZeroButton';

import { Box } from 'styles/layout';
import schema from './validationSchema';
import fieldGroups from './data';

const Scores = () => {
  const form = useFormState(schema, {
    stateSlice: 'consults',
    showAsSnackbar: true,
  });
  const {
    consult,
    consultTabData,
    isEditable,
    saveConsultChange,
    handleFieldChange,
    isMacro,
  } = useConsultData(CONSULT_TABS.SCORES, form.getValues);

  useConsultInitialFormState(consult.id, consultTabData, (values) =>
    form.setFormValues(values),
  );

  return (
    <Grid container>
      <Grid item sm={12} lg={7}>
        <Grid item xs={12}>
          <Box mb={3}>
            <DateTimeInput
              control={form.control}
              name={fields.nihssExamTimeAt.name}
              label={fields.nihssExamTimeAt.label}
              onChange={handleFieldChange}
              disabled={isMacro || !isEditable}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mb={3}>
            <SetZeroButton
              form={form}
              disabled={!isEditable}
              label="Set NIHSS to 0"
              group={fieldGroups.NIHSS}
              onSave={saveConsultChange}
            />
          </Box>
        </Grid>
        <ScoreGroup
          form={form}
          group={fieldGroups.NIHSS}
          disabled={!isEditable}
          onSave={saveConsultChange}
        />
      </Grid>
      <Grid item sm={12} lg={5}>
        <ScoreGroup
          form={form}
          group={fieldGroups.GCS}
          disabled={!isEditable}
          onSave={saveConsultChange}
        />
        <ScoreGroup
          form={form}
          group={fieldGroups.MRS}
          disabled={!isEditable}
          onSave={saveConsultChange}
        />
        <ScoreGroup
          form={form}
          group={fieldGroups.ICH}
          disabled={!isEditable}
          onSave={saveConsultChange}
        />
        <ScoreGroup
          form={form}
          group={fieldGroups.HUNT}
          disabled={!isEditable}
          onSave={saveConsultChange}
        />
      </Grid>
    </Grid>
  );
};

export default Scores;
