import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Add } from '@mui/icons-material';

import api from 'api';
import {
  useMountEffect,
  useFormState,
  useRequest,
  useProfile,
} from 'shared/hooks';
import { MAX_PAGE_SIZE } from 'utils/constants';
import Button from 'shared/components/buttons/ActionButton/ActionButton';
import { ConsultRequestStatusEnum, ServiceTypeLabels } from 'utils/enums';
import { mapStringIdToNumber } from 'utils/mappers';
import { getFacilityNetworks } from 'utils/helpers';
import SearchField from 'shared/components/forms/SearchField/SearchField';
import ToggleButtonGroup from 'shared/components/forms/ToggleButtonGroup/ToggleButtonGroup';
import Autocomplete from 'shared/components/forms/Autocomplete';
import DateTimeFilter from 'features/consults/components/ConsultFilters/DateTimeFilter';
import { FlexBoxSpaceBetween, FlexBoxAlign } from 'styles/layout';

import { FiltersWrapper, FilterBox } from './ConsultRequestFilters.style';

const ConsultRequestFilters = ({
  filters,
  onFilterChange,
  onCreateNewConsultRequest,
}) => {
  const { control, register, setFormValues } = useFormState();
  const { profileData, isCustomerOnly } = useProfile();

  const { doRequest: fetchFacilities, data: facilityData } = useRequest(
    api.getCustomerFacilities,
  );

  const facilities = useMemo(
    () => facilityData?.facilities ?? [],
    [facilityData],
  );

  const serviceOptions = useMemo(
    () =>
      getFacilityNetworks(facilities).map((service) => ({
        id: service,
        name: ServiceTypeLabels[service],
      })),
    [facilities],
  );

  useMountEffect(() => {
    fetchFacilities({ pageSize: MAX_PAGE_SIZE });
  });

  useEffect(() => {
    setFormValues({
      ...filters,
      facilityId: mapStringIdToNumber(filters.facilityId),
    });
  }, [filters, setFormValues]);

  return (
    <FlexBoxSpaceBetween mt={0.5} mb={2.5}>
      <FiltersWrapper>
        {facilities.length > 1 && (
          <FilterBox>
            <Autocomplete
              control={control}
              name="facilityId"
              placeholder="Facility"
              options={facilities}
              handleChange={(value) => onFilterChange({ facilityId: value })}
              groupBy={(o) => o.healthSystemName}
            />
          </FilterBox>
        )}
        <FilterBox>
          <DateTimeFilter
            name="fromCreatedAt"
            control={control}
            placeholder="Created Since"
            handleFilter={onFilterChange}
          />
        </FilterBox>
        <FilterBox>
          <DateTimeFilter
            name="toCreatedAt"
            control={control}
            placeholder="Created Until"
            handleFilter={onFilterChange}
          />
        </FilterBox>
        <FilterBox>
          <Autocomplete
            control={control}
            name="consultType"
            placeholder="Service"
            options={serviceOptions}
            handleChange={(value) => onFilterChange({ consultType: value })}
          />
        </FilterBox>
        <FilterBox>
          <SearchField
            {...register('query')}
            onChange={(value) => onFilterChange({ query: value })}
          />
        </FilterBox>
      </FiltersWrapper>
      <FlexBoxAlign>
        {facilities.length > 0 && (
          <Button
            type="button"
            color="secondary"
            onClick={onCreateNewConsultRequest}
            icon={<Add />}
          >
            Send New Page
          </Button>
        )}
        {(profileData.viewClosedConsultRequests || !isCustomerOnly) && (
          <FlexBoxAlign ml={2}>
            <ToggleButtonGroup
              buttons={[
                ConsultRequestStatusEnum.OPEN,
                ConsultRequestStatusEnum.CLOSED,
              ].map((v) => ({ value: v, content: v }))}
              control={control}
              name="state"
              handleChange={(value) => onFilterChange({ state: value })}
            />
          </FlexBoxAlign>
        )}
      </FlexBoxAlign>
    </FlexBoxSpaceBetween>
  );
};

ConsultRequestFilters.propTypes = {
  filters: PropTypes.shape({
    query: PropTypes.string,
    facilityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onCreateNewConsultRequest: PropTypes.func.isRequired,
};

export default ConsultRequestFilters;
