import { ServiceTypeEnum, EegConsultTypes } from 'utils/enums';
import {
  mapChipFieldOptions,
  mapToggleFieldOptions,
} from 'features/consults/utils/formFields';
import { eegDemographicsFields as fields } from 'features/consults/shared/tabs/Demographics/formFields';

export const eEGTypeFields = (consultType, getValues) => {
  const typeField =
    consultType === ServiceTypeEnum.PEDS_EEG
      ? fields.eegPedsConsultType
      : fields.consultType;
  const typeFieldValue = getValues(typeField.name);
  return [
    mapToggleFieldOptions(typeField),
    ...([EegConsultTypes.CEEG, EegConsultTypes.CERIBELL].includes(
      typeFieldValue,
    )
      ? [mapToggleFieldOptions(fields.observationType)]
      : []),
    mapToggleFieldOptions(fields.videoType),
  ];
};

export const demographicFields1 = [
  mapToggleFieldOptions(fields.eegPatientLocation),
  mapChipFieldOptions(fields.eegStatOptions),
];

export const demographicFields2 = [
  fields.age,
  mapToggleFieldOptions(fields.gender),
];

export const demographicFields3 = [
  fields.referringProvider,
  fields.callBackPhone,
  fields.referringTechnician,
  fields.techCallbackNumber,
  fields.notes,
];

export const demographicFields4 = [fields.initialPageAt, fields.returnCallAt];
