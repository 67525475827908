import React from 'react';

import api from 'api';
import {
  useMountEffect,
  useUpdateEffect,
  useFeedback,
  useFormState,
  useRequest,
} from 'shared/hooks';
import { isEmptyValue } from 'features/consults/utils/validators';

import useGoBack from 'navigation/hooks/useGoBack';
import BackButton from 'navigation/components/BackButton/BackButton';
import FormWrapper from 'shared/components/forms/FormWrapper/FormWrapper';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import { FlexBoxColumn, FlexBoxColumnFull, FlexBoxAlign } from 'styles/layout';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { getFacilityNetworks } from 'utils/helpers';

import schema from './validationSchema';
import ConsultRequestForm from './ConsultRequestForm';
import { consultTypeOptions, formFields } from './data';

const NewConsultRequestPage = () => {
  const { clearDisplay, errorDisplay } = useFeedback();
  const { goBack } = useGoBack();
  const [isSubmitting, setSubmitting] = React.useState(false);

  const { doRequest: fetchFacilities, data: facilityData } = useRequest(
    api.getCustomerFacilities,
  );

  useMountEffect(() => {
    fetchFacilities({
      pageSize: MAX_PAGE_SIZE,
      filters: { activeOnly: 'true' },
    });
  });

  const facilities = React.useMemo(
    () => facilityData?.facilities ?? [],
    [facilityData],
  );

  const { handleSubmit, setValue, canSubmitForm, ...form } = useFormState(
    schema,
    {
      stateSlice: 'consultRequests',
      showAsSnackbar: true,
    },
  );

  const selectedFacilityId = form.watch(formFields.facility);

  const handleCreateConsultRequest = async ({
    facility,
    neuroDirectBeamInRequested,
    statReadRequested,
    ...moreValues
  }) => {
    const consultRequestData = Object.entries(moreValues).reduce(
      (acc, [key, val]) => ({
        ...acc,
        [key]: isEmptyValue(val) ? null : val,
      }),
      {
        facilityId: facility,
        neuroDirectBeamInRequested: !!neuroDirectBeamInRequested?.length,
        statReadRequested: !!statReadRequested?.length,
      },
    );
    clearDisplay();
    setSubmitting(true);
    await api
      .createConsultRequest(consultRequestData)
      .then(() => goBack())
      .catch(({ response }) => {
        errorDisplay(response?.data?.title || 'Error creating consult request');
        setSubmitting(false);
      });
  };

  const typeOptions = React.useMemo(() => {
    const availableNetworks = getFacilityNetworks(facilities);

    const selectedFacility = facilities.find(
      (f) => selectedFacilityId === f.id,
    );
    const selectedFacilityServices = selectedFacility
      ? getFacilityNetworks([selectedFacility])
      : null;

    const returnOptions = consultTypeOptions.filter((consultType) =>
      availableNetworks.includes(consultType.network),
    );

    return selectedFacilityServices
      ? returnOptions.map((opt) => ({
          ...opt,
          isDisabled: !selectedFacilityServices.includes(opt.network),
        }))
      : returnOptions;
  }, [facilities, selectedFacilityId]);

  useUpdateEffect(() => {
    if (facilities.length !== 1) return;
    setValue(formFields.facility, facilities[0].id);
  }, [facilities]);

  useUpdateEffect(() => {
    if (typeOptions.length !== 1) return;
    setValue(formFields.consultType, typeOptions[0].value);
  }, [typeOptions]);

  return (
    <FlexBoxColumn height="100%">
      <FlexBoxAlign>
        <BackButton onBack={() => goBack()} />
        <PageTitle>New Consult Request</PageTitle>
      </FlexBoxAlign>
      <FlexBoxColumnFull my={2} px={1.25}>
        <FormWrapper
          onSubmit={handleSubmit(handleCreateConsultRequest)}
          isSubmitDisabled={isSubmitting || !canSubmitForm}
          onCancel={() => goBack()}
        >
          <ConsultRequestForm
            formProps={{ ...form, setValue }}
            facilities={facilities}
            typeOptions={typeOptions}
          />
        </FormWrapper>
      </FlexBoxColumnFull>
    </FlexBoxColumn>
  );
};

export default NewConsultRequestPage;
