import React from 'react';
import PropTypes from 'prop-types';

import DateTimeInput from 'features/consults/components/tabFields/DateTimeInput';
import { TIMESTAMP_FORMAT, formatDate } from 'utils/dates';

const DateTimeFilter = ({
  control,
  name,
  placeholder,
  handleFilter,
  ...moreProps
}) => {
  const handleChange = (fieldName) => {
    // eslint-disable-next-line no-underscore-dangle
    const value = control._formValues[fieldName];
    handleFilter({
      [fieldName]: value ? formatDate(new Date(value), TIMESTAMP_FORMAT) : null,
    });
  };

  return (
    <DateTimeInput
      {...moreProps}
      inputStyle={{ minWidth: '220px' }}
      control={control}
      name={name}
      onChange={handleChange}
      placeholder={placeholder}
    />
  );
};

DateTimeFilter.propTypes = {
  control: PropTypes.shape({
    _formValues: PropTypes.shape({}),
  }).isRequired,
  handleFilter: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default DateTimeFilter;
