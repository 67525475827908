import { getNamesWithTitle } from 'utils/helpers';

export const mapErrorResponse = ({ payload, error }) => {
  if (payload) {
    if (payload.errors) {
      const reducedErrors = Object.values(payload.errors).reduce((res, err) => {
        if (err.length) return [...res, ...err];
        return res;
      }, []);
      if (reducedErrors.length) return reducedErrors;
    }

    if (payload.title) {
      return payload.title;
    }
  }

  if (error) {
    return error.message;
  }

  return 'Something went wrong. Please try again.';
};

// Convert empty strings into "null" before sending a request to the API
export const mapFormFieldsToApiFormat = (formObj) =>
  formObj &&
  Object.entries(formObj).reduce((acc, [key, value]) => {
    if (typeof value !== 'object') {
      acc[key] = value !== '' ? value : null;

      return acc;
    }

    acc[key] = mapFormFieldsToApiFormat(value);
    return acc;
  }, {});

export const mapPhoneNumberToMask = (phoneNumber) => {
  if (!phoneNumber) {
    return null;
  }

  const onlyDigits = phoneNumber.substr(2); // Remove country code
  const areaCode = onlyDigits.substr(0, 3);
  const number = `${onlyDigits.substr(3, 3)}-${onlyDigits.substr(-4)}`;

  return `${areaCode}-${number}`;
};

const generateUniqueLabel = (currentLabel, existingLabels) => {
  let count = 1;
  while (
    existingLabels.includes(currentLabel + (count > 1 ? ` (${count})` : ''))
  ) {
    count += 1;
  }
  return count > 1 ? `${currentLabel} (${count})` : currentLabel;
};

export const mapDoctorNames = (doctors, value = 'id', label = 'name') =>
  doctors.reduce((res, { id, firstName, lastName, title }) => {
    const newLabel = getNamesWithTitle(firstName, lastName, title);
    const uniqueLabel = generateUniqueLabel(
      newLabel,
      res.map((item) => item[label]),
    );

    return [...res, { [value]: id, [label]: uniqueLabel }];
  }, []);

export const mapStringIdToNumber = (id) => (id ? Number(id) : null);

export const mapOptionsToSelect = (options) =>
  options.map(({ label, value }) => ({ value, text: label }));
