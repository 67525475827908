import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { renderToString } from 'react-dom/server';

import { Dialog, DialogTitle } from '@mui/material';

import api from 'api';
import { useConsult } from 'shared/context/ConsultContext';
import { adminActions } from 'features/admin/store/slice';
import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';

import { HiddenWrapper } from 'styles/layout';
import SummaryNote from 'features/consults/components/SummaryNote/SummaryNote';
import {
  extractSignature,
  buildNewSignature,
} from 'features/consults/shared/tabs/Summary/utils';

import EditConsultForm from './EditConsultForm';

const EditConsultModal = ({ handleClose }) => {
  const { consult, consultTimezone, consultProviders } = useConsult();
  const summaryContainerRef = React.useRef(null);
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = React.useState(false);

  const onSubmit = (saveData) => {
    setSubmitting(true);
    dispatch(
      adminActions.updateAdminConsult({
        consultId: consult.id,
        data: {
          ...saveData,
          facilityId: {
            ...saveData.facilityId,
            value: Number(saveData.facilityId.value),
          },
        },
      }),
    );
  };

  usePerformActionOnSuccess(
    SuccessActionTypesEnum.UPDATED_ADMIN_CONSULT,
    async ({ payload: savedConsult }) => {
      setSubmitting(false);
      const { summary } = savedConsult;

      const signature =
        extractSignature(summary) ||
        buildNewSignature({
          signatureData: savedConsult.signature,
          consultProviders,
          consultTimezone,
        }).trim();

      const eSignature = (
        <div
          style={{ marginTop: '10px' }}
        >{`Electronically signed at ${signature}`}</div>
      );

      const { innerText, innerHTML } = summaryContainerRef.current;

      const {
        data: { consult: updatedConsult },
      } = await api.updateConsultSummary({
        consultId: consult.id,
        summary: `${innerText}${eSignature.props.children}`,
        summaryFormatted: `${innerHTML}${renderToString(eSignature)}`,
      });

      dispatch(adminActions.updateConsult(updatedConsult));

      handleClose();
    },
  );

  return (
    <Dialog open onClose={handleClose} maxWidth="md">
      <DialogTitle sx={{ fontSize: '1.1rem' }}>
        <span>
          This is a Closed Consult. The information entered here has likely
          already been added to the client EMR.
        </span>
        <br />
        <span>
          Any changes made here will NOT be automatically reflected on the
          Client EMR.
        </span>
      </DialogTitle>
      <EditConsultForm
        consult={consult}
        handleClose={handleClose}
        onEdit={onSubmit}
        isSubmitting={isSubmitting}
      />
      <HiddenWrapper>
        <SummaryNote ref={summaryContainerRef} isClosed={false} />
      </HiddenWrapper>
    </Dialog>
  );
};

EditConsultModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default EditConsultModal;
