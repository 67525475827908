import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import HealthSystemSelect from 'shared/components/data/forms/HealthSystemSelect';
import FacilitySelect from 'shared/components/data/forms/FacilitySelect';
import ProviderSelect from 'shared/components/data/forms/ProviderSelect';
import NetworkSelect from 'shared/components/data/forms/NetworkSelect';

import { useFormState } from 'shared/hooks';
import SearchField from 'shared/components/forms/SearchField/SearchField';
import Select from 'shared/components/forms/Select';

import {
  ConsultIntegrationOptions,
  consultFilterStateLabels,
  neuroConsultOptions,
  teleNeuroConsultOptions,
  eegConsultOptions,
} from 'features/consults/utils/constants';
import { ServiceTypeEnum, ConsultFilterEnum } from 'utils/enums';
import { mapConsultFilters } from 'features/consults/utils/mappers';
import { mapOptionsToSelect } from 'utils/mappers';

import DateTimeFilter from './DateTimeFilter';
import { FilterWrapper, FilterElement } from './ConsultFilters.style';

const typeFilterConfig = {
  [ServiceTypeEnum.NEURO]: {
    name: 'NeuroConsultType',
    options: neuroConsultOptions,
  },
  [ServiceTypeEnum.TELE_NEURO]: {
    name: 'TeleNeuroHospitalConsultTypes',
    options: teleNeuroConsultOptions,
  },
  [ServiceTypeEnum.EEG]: {
    name: 'EegConsultType',
    options: eegConsultOptions,
  },
  [ServiceTypeEnum.PEDS_EEG]: {
    name: 'EegPediatricConsultType',
    options: eegConsultOptions,
  },
};

const ConsultFilters = ({
  filters,
  handleFilter,
  consultType,
  activeQuickFilter,
}) => {
  const { register, control, setFormValues, watch } = useFormState();

  const { name: typeFilterName, options: typeFilterOptions } = useMemo(
    () => typeFilterConfig[consultType] || {},
    [consultType],
  );

  useEffect(() => {
    setFormValues(mapConsultFilters(filters));
  }, [setFormValues, filters]);

  return (
    <FilterWrapper>
      {activeQuickFilter !== ConsultFilterEnum.OPEN && (
        <>
          <FilterElement>
            <DateTimeFilter
              control={control}
              name="fromCreatedAt"
              placeholder="From"
              handleFilter={handleFilter}
            />
          </FilterElement>
          <FilterElement>
            <DateTimeFilter
              control={control}
              name="toCreatedAt"
              placeholder="To"
              handleFilter={handleFilter}
            />
          </FilterElement>
          {consultType !== ServiceTypeEnum.CLINIC && (
            <>
              <FilterElement>
                <HealthSystemSelect
                  control={control}
                  placeholder="Healthcare System"
                  name="healthcareSystemId"
                  handleChange={(value) =>
                    handleFilter({
                      healthcareSystemId: value,
                      facilityId: null,
                    })
                  }
                />
              </FilterElement>
              <FilterElement>
                <FacilitySelect
                  control={control}
                  placeholder="Facility"
                  name="facilityId"
                  handleChange={(value) =>
                    handleFilter({
                      facilityId: value,
                    })
                  }
                  networkType={consultType}
                  requiredSystem
                  healthSystem={watch('healthcareSystemId')}
                />
              </FilterElement>
              <FilterElement>
                <NetworkSelect
                  control={control}
                  placeholder="Network"
                  name="networkId"
                  handleChange={(value) =>
                    handleFilter({
                      networkId: value,
                    })
                  }
                />
              </FilterElement>
            </>
          )}
          <FilterElement>
            <ProviderSelect
              control={control}
              placeholder="Provider"
              name="assignToUserIds"
              displayUnassigned
              consultType={consultType}
              handleChange={(value) =>
                handleFilter({
                  assignToUserIds: value,
                })
              }
            />
          </FilterElement>
          <FilterElement>
            <Select
              control={control}
              name="state"
              placeholder="Status"
              options={consultFilterStateLabels}
              handleChange={({ target }) =>
                handleFilter({ state: target.value })
              }
            />
          </FilterElement>
          {consultType !== ServiceTypeEnum.CLINIC && (
            <FilterElement>
              <Select
                control={control}
                name={typeFilterName}
                placeholder="Type"
                options={mapOptionsToSelect(typeFilterOptions)}
                handleChange={({ target }) =>
                  handleFilter({ [target.name]: target.value })
                }
              />
            </FilterElement>
          )}
          {consultType === ServiceTypeEnum.NEURO && (
            <FilterElement>
              <Select
                control={control}
                name="integrationStatusList"
                placeholder="Integration Status"
                options={ConsultIntegrationOptions}
                handleChange={({ target }) =>
                  handleFilter({ [target.name]: target.value })
                }
              />
            </FilterElement>
          )}
        </>
      )}
      <FilterElement>
        <SearchField
          {...register('query')}
          onChange={(value) => handleFilter({ query: value })}
        />
      </FilterElement>
    </FilterWrapper>
  );
};

ConsultFilters.propTypes = {
  filters: PropTypes.shape({
    query: PropTypes.string,
  }).isRequired,
  handleFilter: PropTypes.func.isRequired,
  consultType: PropTypes.string.isRequired,
  activeQuickFilter: PropTypes.string.isRequired,
};

export default ConsultFilters;
