import { RESET_OPTION, CONSULT_TABS } from 'features/consults/utils/constants';
import { ServiceTypeEnum, ConsultAttestationEnum } from 'utils/enums';

import demographicsFields, {
  teleNeuroDemographicsFields,
  eegDemographicsFields,
} from 'features/consults/shared/tabs/Demographics/formFields';
import eegFields from 'features/consults/pages/eeg/tabs/EEG/formFields';
import signatureFields from 'features/consults/shared/tabs/Signature/formFields';

export const isEmptyValue = (value) => {
  const emptyValues = ['', RESET_OPTION.value, null, undefined];
  return Array.isArray(value) ? !value.length : emptyValues.includes(value);
};

const neuroSignatureRequiredFields = (consult, consultFacility) => [
  signatureFields.attestationOfConsultCompletion,
  ...([
    ConsultAttestationEnum.phoneOnly,
    ConsultAttestationEnum.followUpPhoneCall,
  ].includes(
    consult[CONSULT_TABS.SIGNATURE][
      signatureFields.attestationOfConsultCompletion.name
    ],
  )
    ? []
    : [
        signatureFields.totalFaceToFaceTime,
        ...(consultFacility?.r1Integration
          ? [signatureFields.billingCode, signatureFields.icdCodes]
          : []),
      ]),
];

export const isEegcptCodeCodeRequired = (consult, consultFacility) =>
  consultFacility?.r1Integration &&
  ![
    ConsultAttestationEnum.followUpPhoneCall,
    ConsultAttestationEnum.hookUp,
  ].includes(
    consult[CONSULT_TABS.SIGNATURE][
      signatureFields.attestationOfEEGCompletion.name
    ],
  );

const eegRequiredFields = (consult, consultFacility, isPeds) => ({
  [CONSULT_TABS.DEMOGRAPHICS]: [
    demographicsFields.firstName,
    demographicsFields.lastName,
    demographicsFields.facility,
    isPeds
      ? eegDemographicsFields.eegPedsConsultType
      : demographicsFields.consultType,
  ],
  [CONSULT_TABS.EEG]: [
    eegFields.startTimeOfEEGReadAt,
    eegFields.stopTimeOfEEGReadAt,
    eegFields.duration,
  ],
  [CONSULT_TABS.SIGNATURE]: [
    signatureFields.attestationOfEEGCompletion,
    ...(isEegcptCodeCodeRequired(consult, consultFacility)
      ? [signatureFields.eegcptCode, signatureFields.icdCodes]
      : []),
  ],
});

const requiredConsultFields = (consult, consultFacility) => ({
  [ServiceTypeEnum.NEURO]: {
    [CONSULT_TABS.DEMOGRAPHICS]: [
      demographicsFields.consultType,
      demographicsFields.neuroPatientLocation,
      demographicsFields.firstName,
      demographicsFields.lastName,
      demographicsFields.facility,
    ],
    [CONSULT_TABS.SIGNATURE]: neuroSignatureRequiredFields(
      consult,
      consultFacility,
    ),
  },
  [ServiceTypeEnum.TELE_NEURO]: {
    [CONSULT_TABS.DEMOGRAPHICS]: [
      teleNeuroDemographicsFields.consultType,
      teleNeuroDemographicsFields.firstName,
      teleNeuroDemographicsFields.lastName,
      teleNeuroDemographicsFields.dateOfBirth,
      teleNeuroDemographicsFields.facility,
      teleNeuroDemographicsFields.dateOfAdmission,
      teleNeuroDemographicsFields.dateOfInitialConsultation,
      teleNeuroDemographicsFields.dateOfService,
    ],
    [CONSULT_TABS.SIGNATURE]: neuroSignatureRequiredFields(
      consult,
      consultFacility,
    ),
  },
  [ServiceTypeEnum.CLINIC]: {
    [CONSULT_TABS.DEMOGRAPHICS]: [
      demographicsFields.firstName,
      demographicsFields.lastName,
    ],
    [CONSULT_TABS.SIGNATURE]: [signatureFields.totalFaceToFaceTime],
  },
  [ServiceTypeEnum.EEG]: eegRequiredFields(consult, consultFacility),
  [ServiceTypeEnum.PEDS_EEG]: eegRequiredFields(consult, consultFacility, true),
});

export const consultErrors = (consult, consultType, consultFacility) => {
  const requiredTabs =
    requiredConsultFields(consult, consultFacility)[consultType] ?? {};

  return Object.entries(requiredTabs).reduce((res, [tabKey, tabElements]) => {
    const tabError = tabElements?.reduce(
      (res2, { name }) =>
        isEmptyValue(consult[tabKey][name]) ? [...res2, name] : res2,
      [],
    );
    return tabError.length ? { ...res, [tabKey]: tabError } : res;
  }, {});
};
