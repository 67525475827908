import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';
import styled from '@emotion/styled';

import ChipList from 'shared/components/forms/Chip/ChipList';
import DatePicker from 'shared/components/forms/DatePicker/DatePicker';
import InputField from 'shared/components/forms/InputField/InputField';
import Autocomplete from 'shared/components/forms/Autocomplete';
import PhoneNumberInput from 'shared/components/forms/PhoneNumberInput/PhoneNumberInput';
import ToggleButtonGroup from 'shared/components/forms/ToggleButtonGroup/ToggleButtonGroup';
import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';
import ConsultRequestTypeSelect from 'features/customer/components/ConsultRequest/ConsultRequestTypeSelect';
import { Box } from 'styles/layout';

import { ServiceTypeEnum, NeuroConsultTypes } from 'utils/enums';
import { videoOptions } from 'features/consults/shared/tabs/Demographics/formFields';

import {
  genderOptions,
  patientLocationOptions,
  eegConsultOptions,
  teleneuroConsultOptions,
  formFields,
  teleNeuroLabel,
} from './data';

const FlexGridColumn = styled(Grid)`
  flex-direction: column;
`;

const ConsultRequestForm = ({
  formProps: { control, register, setValue, getFieldError, watch },
  facilities = [],
  typeOptions = [],
  readOnly,
}) => {
  const selectedConsultType = watch(formFields.consultType);

  const isEeg = React.useMemo(
    () =>
      [ServiceTypeEnum.EEG, ServiceTypeEnum.PEDS_EEG].includes(
        selectedConsultType,
      ),
    [selectedConsultType],
  );

  return (
    <GridWrapper spacing={10}>
      <FlexGridColumn container item sm={12} lg={5}>
        <Box mb={3}>
          <InputField
            {...register(formFields.firstName, {
              required: true,
              disabled: readOnly,
            })}
            label="First Name"
            required
            error={getFieldError(formFields.firstName)}
          />
        </Box>
        <Box mb={3}>
          <InputField
            {...register(formFields.lastName, {
              required: true,
              disabled: readOnly,
            })}
            label="Last Name"
            required
            error={getFieldError(formFields.lastName)}
          />
        </Box>
        <Box mb={3}>
          <DatePicker
            control={control}
            name={formFields.dateOfBirth}
            label="Date of Birth"
            error={getFieldError(formFields.dateOfBirth)}
            openTo="year"
            disableFuture
            disabled={readOnly}
          />
        </Box>
        <Box mb={3}>
          <ToggleButtonGroup
            control={control}
            name={formFields.gender}
            title="Gender"
            compact
            buttons={genderOptions}
            disabled={readOnly}
          />
        </Box>
        <Box mb={3}>
          <Autocomplete
            control={control}
            name={formFields.facility}
            label="Facility"
            options={facilities}
            required
            groupBy={({ healthSystemName }) => healthSystemName}
            disabled={readOnly || facilities.length < 2}
            error={getFieldError(formFields.facility)}
          />
        </Box>
        <Box mb={1}>
          <ConsultRequestTypeSelect
            control={control}
            name={formFields.consultType}
            options={typeOptions}
            handleChange={() => {
              setValue(formFields.eegReadConsultType, null);
              setValue(formFields.neuroHospitalConsultType, null);
            }}
            error={getFieldError(formFields.consultType)}
            disabled={readOnly}
          />
        </Box>

        <Box
          mb={1}
          display={
            selectedConsultType === ServiceTypeEnum.TELE_NEURO
              ? 'block'
              : 'none'
          }
        >
          <ToggleButtonGroup
            control={control}
            name={formFields.neuroHospitalConsultType}
            title={teleNeuroLabel}
            compact
            required
            buttons={teleneuroConsultOptions}
            error={getFieldError(formFields.neuroHospitalConsultType)}
            disabled={readOnly}
          />
        </Box>

        <Box display={isEeg ? 'block' : 'none'}>
          <Box mb={1}>
            <ToggleButtonGroup
              control={control}
              name={formFields.eegReadConsultType}
              title="Type of EEG Read"
              compact
              required
              buttons={eegConsultOptions(selectedConsultType)}
              error={getFieldError(formFields.eegReadConsultType)}
              disabled={readOnly}
            />
          </Box>
          <Box mb={3}>
            <ToggleButtonGroup
              control={control}
              name={formFields.videoType}
              compact
              required
              buttons={videoOptions.map((v) => ({
                ...v,
                content: v.label,
              }))}
              error={getFieldError(formFields.videoType)}
              disabled={readOnly}
            />
          </Box>
        </Box>

        <Box mb={3}>
          <ToggleButtonGroup
            control={control}
            name={formFields.patientLocation}
            title="Patient Location"
            compact
            required
            buttons={patientLocationOptions(selectedConsultType)}
            error={getFieldError(formFields.patientLocation)}
            disabled={readOnly}
          />
        </Box>
      </FlexGridColumn>
      <FlexGridColumn container item sm={12} lg={5}>
        <Box mb={3}>
          <InputField
            {...register(formFields.provider, {
              disabled: readOnly,
            })}
            label="Provider Requesting Consult"
            error={getFieldError(formFields.provider)}
          />
        </Box>
        <Box mb={3}>
          <PhoneNumberInput
            control={control}
            name={formFields.phoneNumber}
            label="Phone"
            required
            error={getFieldError(formFields.phoneNumber)}
            disabled={readOnly}
          />
        </Box>

        <Box display={isEeg ? 'block' : 'none'}>
          <Box mb={3}>
            <InputField
              {...register(formFields.eegTechName, {
                disabled: readOnly,
              })}
              label="Referring Technician"
              error={getFieldError(formFields.eegTechName)}
            />
          </Box>
          <Box mb={3}>
            {isEeg && (
              <PhoneNumberInput
                control={control}
                name={formFields.eegTechCallBackNumber}
                label="Tech Callback Number"
                required
                error={getFieldError(formFields.eegTechCallBackNumber)}
                disabled={readOnly}
              />
            )}
          </Box>
          <Box mb={3}>
            <ChipList
              control={control}
              name={formFields.statReadRequested}
              options={[
                {
                  value: formFields.statReadRequested,
                  text: 'STAT Read Requested',
                },
              ]}
              disabled={readOnly}
            />
          </Box>
        </Box>

        <Box display={isEeg ? 'none' : 'block'}>
          <Box mb={3}>
            <InputField
              {...register(formFields.neuroCameraName, {
                disabled: readOnly,
              })}
              label="Camera Name"
              error={getFieldError(formFields.neuroCameraName)}
              disabled={readOnly}
            />
          </Box>

          <Box
            mb={3}
            display={
              selectedConsultType === NeuroConsultTypes.ACUTE_LEVEL1
                ? 'block'
                : 'none'
            }
          >
            <ChipList
              control={control}
              name={formFields.neuroDirectBeamInRequested}
              options={[
                {
                  value: 'directBeamIn',
                  text: 'Direct Beam-in Requested',
                },
              ]}
              disabled={readOnly}
            />
          </Box>
        </Box>

        <Box mb={3}>
          <InputField
            {...register(formFields.notes, {
              disabled: readOnly,
            })}
            label="Notes"
            error={getFieldError(formFields.notes)}
            multiline
            rows={8}
          />
        </Box>
      </FlexGridColumn>
    </GridWrapper>
  );
};

ConsultRequestForm.propTypes = {
  formProps: PropTypes.shape({
    register: PropTypes.func.isRequired,
    control: PropTypes.shape({}).isRequired,
    setValue: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    getFieldError: PropTypes.func.isRequired,
  }).isRequired,
  facilities: PropTypes.arrayOf(PropTypes.shape({})),
  readOnly: PropTypes.bool,
  typeOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ConsultRequestForm;
