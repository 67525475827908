import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Dialog, DialogContent } from '@mui/material';

import { usePerformActionOnSuccess } from 'shared/hooks';

import { appStateActions } from 'store/appStateSlice';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import { IntegrationStatusEnum } from 'utils/enums';
import { consultsActions } from 'features/consults/store/slice';
import { SnackbarTypeEnum } from 'shared/components/feedback/Snackbar/SnackbarTypes.enum';

import { Box, FlexBoxJustify } from 'styles/layout';
import ConsultIntegrationMerge from 'features/consults/components/ConsultIntegration/ConsultIntegrationMerge';
import ConsultIntegrationTable from 'features/consults/components/ConsultIntegration/ConsultIntegrationTable';

const ConsultIntegration = ({ consult, consultType }) => {
  const [toMerge, setToMerge] = React.useState(null);
  const dispatch = useDispatch();

  const onMerge = () => {
    dispatch(
      consultsActions.linkOrder({
        hl7OrderId: toMerge?.id,
        consultId: consult.id,
        consultType,
      }),
    );
  };

  usePerformActionOnSuccess(SuccessActionTypesEnum.ORDER_LINKED, () => {
    setToMerge(null);
    dispatch(
      consultsActions.getConsultById({
        consultId: consult.id,
        forceReload: true,
      }),
    );
    dispatch(
      appStateActions.showSnackbar({
        text: 'Linked successfully',
        type: SnackbarTypeEnum.SUCCESS,
        duration: 5000,
      }),
    );
  });

  if (consult?.integrationStatus === IntegrationStatusEnum.MATCHED) {
    return (
      <FlexBoxJustify>
        <h3>Matched</h3>
      </FlexBoxJustify>
    );
  }

  return (
    <>
      <Box style={{ maxWidth: '25rem' }}>
        <ConsultIntegrationTable
          consultId={consult.id}
          consultType={consultType}
          onReview={(item) => setToMerge(item)}
          last5Days
          hideColumns={['matchType', 'createdAt']}
        />
      </Box>
      {!!toMerge && (
        <Dialog open maxWidth="lg" onClose={() => setToMerge(null)}>
          <DialogContent>
            <ConsultIntegrationMerge
              consult={consult}
              order={toMerge}
              onClose={() => setToMerge(null)}
              onMerge={onMerge}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

ConsultIntegration.propTypes = {
  consult: PropTypes.shape({
    id: PropTypes.string,
    integrationStatus: PropTypes.string,
  }).isRequired,
  consultType: PropTypes.string,
};

export default ConsultIntegration;
