import { RESET_OPTION } from 'features/consults/utils/constants';
import { mapChipFieldOptions } from 'features/consults/utils/formFields';

import fields from './formFields';

export const telemedicineOptions = [
  RESET_OPTION,
  { value: 'Telemedicine', content: 'telemedicine' },
  { value: 'PhoneOnly', content: 'phone only' },
];

export const providerLocationOptions = [
  RESET_OPTION,
  { value: 'Clinic', content: 'clinic' },
  { value: 'Hospital', content: 'hospital' },
  { value: 'Home', content: 'home' },
];

export const qualityReviewPanel = {
  elements: [mapChipFieldOptions(fields.qualityReview)],
};

export const qualityCommentPanel = {
  elements: [fields.qualityComment],
};

export const qualityOptionsPanel = {
  elements: [
    mapChipFieldOptions(fields.workflowLogistics),
    mapChipFieldOptions(fields.paging),
    mapChipFieldOptions(fields.emrPacsImages),
    mapChipFieldOptions(fields.equipmentTechIssue),
    mapChipFieldOptions(fields.caseReview),
  ],
};

export const eegIcds = [
  { code: 'R56.9', name: 'Unspecified convulsions' },
  { code: 'R25.9', name: 'Unspecified abnormal involuntary movements' },
  { code: 'R41.82', name: 'Altered mental status, unspecified' },
  { code: 'I63.9', name: 'Cerebral infarction, unspecified' },
  { code: 'R47.01', name: 'Aphasia' },
  { code: 'R53.1', name: 'Weakness' },
  { code: 'R40.20', name: 'Loss of consciousness' },
  { code: 'R20.2', name: 'Paresthesia' },
  { code: 'R29.818', name: 'Transient neurological symptoms' },
];

export const pedsEegIcds = [
  ...eegIcds,
  { code: 'I46.9', name: 'Cardiac arrest' },
  { code: 'P91.60', name: 'HIE' },
];

export const neuroIcds = [
  { code: 'I63.9', name: 'Stroke: Acute Ischemic' },
  { code: 'R41.82', name: 'Altered Mentation' },
  { code: 'G93.1', name: 'Anoxic brain injury' },
  { code: 'R47.01', name: 'Aphasia' },
  { code: 'G51.0', name: 'Bells Palsy' },
  { code: 'G93.9', name: 'Brain Mass' },
  { code: 'G61.81', name: 'CIDP' },
  { code: 'I67.6', name: 'Cerebral Venous Thrombosis' },
  { code: 'R47.1', name: 'Dysarthria' },
  { code: 'G93.40', name: 'Encephalopathy' },
  { code: 'G61.0', name: 'Guillain Barre Syndrome' },
  { code: 'R51.9', name: 'Headache' },
  { code: 'I61.9', name: 'Intracerebral Hemorrhage' },
  { code: 'G03.9', name: 'Meningitis' },
  { code: 'G43.9', name: 'Migraine' },
  { code: 'G43.809', name: 'Migraine: Complex' },
  { code: 'G35', name: 'Multiple Sclerosis' },
  { code: 'G70.01', name: 'Myasthenia gravis, acute' },
  { code: 'R20.0', name: 'Numbness' },
  { code: 'I67.83', name: 'PRES' },
  { code: 'I60.9', name: 'Subarachnoid Hemorrhage' },
  { code: 'R56.9', name: 'Seizure' },
  { code: 'G40.901', name: 'Status Epilepticus' },
  { code: 'R29.90', name: 'Stroke-like symptoms' },
  { code: 'I62.0', name: 'Subdural' },
  { code: 'R55', name: 'Syncope' },
  { code: 'G45.9', name: 'TIA' },
  { code: 'G37.3', name: 'Transverse myelitis' },
  { code: 'R42', name: 'Vertigo/dizziness' },
  { code: 'H53.9', name: 'Vision changes' },
  { code: 'R53.1', name: 'Weakness' },
];
