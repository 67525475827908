import { useMemo } from 'react';

import sortFields from 'api/sortFields';
import { useProfile } from 'shared/hooks';
import { ConsultFilterEnum, ConsultStates } from 'utils/enums';
import { LAST24HOURS } from 'features/consults/utils/constants';

const getSort = (sortOrder) => ({
  defaultSort: {
    sortBy: sortFields.consults.createdAt,
    sortOrder,
  },
});

const filterList = (assignToUserIds) => ({
  [ConsultFilterEnum.OPEN]: {
    assignToUserIds,
    state: ConsultStates.OPEN,
    ...getSort('asc'),
  },
  [ConsultFilterEnum.ALL]: getSort('desc'),
  [ConsultFilterEnum.LAST_24]: {
    fromCreatedAt: LAST24HOURS,
    ...getSort('desc'),
  },
  [ConsultFilterEnum.MY_LAST_24]: {
    assignToUserIds,
    fromCreatedAt: LAST24HOURS,
    ...getSort('desc'),
  },
  [ConsultFilterEnum.MY_CLOSED]: {
    assignToUserIds,
    state: ConsultStates.CLOSED,
    ...getSort('desc'),
  },
  [ConsultFilterEnum.ALL_CLOSED]: {
    state: ConsultStates.CLOSED,
    ...getSort('desc'),
  },
  [ConsultFilterEnum.ALL_OPEN]: {
    state: ConsultStates.OPEN,
    ...getSort('desc'),
  },
});

const useConsultQuickFilter = (currentFilters) => {
  const { profileData } = useProfile();

  const quickFilters = useMemo(
    () => filterList(String(profileData.id)),
    [profileData.id],
  );

  const activeQuickFilter = useMemo(() => {
    const isMine = profileData.id === Number(currentFilters?.assignToUserIds);

    if (currentFilters.state === ConsultStates.OPEN)
      return isMine ? ConsultFilterEnum.OPEN : ConsultFilterEnum.ALL_OPEN;

    if (currentFilters.state === ConsultStates.CLOSED)
      return isMine
        ? ConsultFilterEnum.MY_CLOSED
        : ConsultFilterEnum.ALL_CLOSED;

    if (currentFilters.fromCreatedAt === LAST24HOURS)
      return isMine ? ConsultFilterEnum.MY_LAST_24 : ConsultFilterEnum.LAST_24;

    return ConsultFilterEnum.ALL;
  }, [profileData.id, currentFilters]);

  return {
    activeQuickFilter,
    quickFilters,
  };
};

export default useConsultQuickFilter;
