import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import Link from '@mui/material/Link';

import InputField from 'shared/components/forms/InputField/InputField';

import { PHONE_MASK_FORMAT, mapInputValue, mapOutputValue } from './utils';

const TextMaskCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={PHONE_MASK_FORMAT}
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      onChange={() => {}}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const PhoneNumberInput = ({
  control,
  name,
  label,
  disabled = false,
  error = null,
  required = false,
  onChange,
  ...props
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue=""
    render={({ field }) => {
      const inputValue = mapInputValue(field.value);

      return (
        <InputField
          {...field}
          {...props}
          required={required}
          label={
            inputValue && label ? (
              <Link href={`tel:${inputValue}`}>{label}</Link>
            ) : (
              label
            )
          }
          error={error}
          disabled={disabled}
          value={inputValue}
          onChange={({ target }) => {
            const newValue = mapOutputValue(target.value);
            if (newValue === field.value) return;
            field.onChange(newValue);
            if (onChange) onChange(newValue);
          }}
          inputComponent={TextMaskCustom}
        />
      );
    }}
  />
);

PhoneNumberInput.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }),
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

export default PhoneNumberInput;
