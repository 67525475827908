import React from 'react';
import PropTypes from 'prop-types';
import { TableBody, TableRow, TableSortLabel } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { v4 as uuid } from 'uuid';

import { Box } from 'styles/layout';
import {
  DataTable,
  TableHeader,
  DataRow,
  HeaderCell,
  DataCell,
  FallbackMsg,
} from './Table.style';

const Table = ({
  headings,
  data,
  fallbackMsg,
  sortBy,
  sortOrder,
  selectedRowId = null,
  onSortChange,
  onClickRow,
}) => {
  const renderTableHeading = ({ id, value, isSortable }, index) => {
    const isSorted = sortBy === id;
    const isAscOrder = sortOrder === 'asc';
    const newOrder = isSorted && isAscOrder ? 'desc' : 'asc';

    const sortableHeading = (
      <TableSortLabel
        active={isSorted}
        IconComponent={isAscOrder ? ArrowDropDown : ArrowDropUp}
        onClick={() => onSortChange(id, newOrder)}
      >
        {value}
      </TableSortLabel>
    );

    // Retrieve the width & alignment of the heading from the first data row
    const { width, align, empty } = data[0].cells[index];

    return (
      <HeaderCell
        key={uuid()}
        width={width}
        align={align}
        _empty={empty ? '_empty' : ''}
      >
        {isSortable ? sortableHeading : value}
      </HeaderCell>
    );
  };

  const renderDataCell = ({ value, width, align, empty }) => (
    <DataCell
      key={uuid()}
      width={width}
      align={align}
      _empty={empty ? '_empty' : ''}
    >
      {value}
    </DataCell>
  );

  if (data.length === 0) {
    return <FallbackMsg>{fallbackMsg}</FallbackMsg>;
  }

  return (
    <Box width="100%">
      <DataTable>
        <TableHeader>
          <TableRow>{headings.map(renderTableHeading)}</TableRow>
        </TableHeader>
        <TableBody>
          {data.map((row, index) => (
            <DataRow
              key={uuid()}
              index={index}
              selected={row.id === selectedRowId}
              onClick={() => (onClickRow ? onClickRow(row.id) : null)}
              clickable={onClickRow ? 1 : 0}
            >
              {row.cells.map(renderDataCell)}
            </DataRow>
          ))}
        </TableBody>
      </DataTable>
    </Box>
  );
};

Table.propTypes = {
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      isSortable: PropTypes.bool,
    }),
  ).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      cells: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.node,
          ]),
          width: PropTypes.string,
          align: PropTypes.string,
          empty: PropTypes.bool,
        }),
      ),
    }),
  ).isRequired,
  fallbackMsg: PropTypes.string,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  selectedRowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSortChange: PropTypes.func,
  onClickRow: PropTypes.func,
};

export default Table;
