import { ServiceTypeEnum } from 'utils/enums';
import {
  getOptions4Type,
  mapChipFieldOptions,
  mapToggleFieldOptions,
} from 'features/consults/utils/formFields';
import fields, {
  thrombolyticValues,
} from 'features/consults/shared/tabs/Plan/formFields';

export const thrombolyticElement = [
  mapToggleFieldOptions(fields.thrombolyticInterventionPredefined),
];

const thrombolyticExclusion = [
  mapChipFieldOptions(
    fields.thrombolyticExclusionLessThanThreeHourWindowPredefined,
  ),
  mapChipFieldOptions(
    fields.thrombolyticExclusionThreeToFourPointFiveHourWindowPredefined,
  ),
  mapToggleFieldOptions(fields.thrombolyticExclusionPredefined),
  fields.thrombolyticExclusionFreeText,
];

export const thrombolyticElements = {
  [thrombolyticValues.IVThrombolytic]: [
    mapToggleFieldOptions(fields.dose),
    mapChipFieldOptions(fields.iaExclusionPredefined),
    fields.iaExclusionFreeText,
    mapChipFieldOptions(fields.thrombolyticIntraarterialExclusionPredefined),
    fields.timeIVThrombolyticRecommendedMountainTime,
  ],
  [thrombolyticValues.IAIntervention]: [
    ...thrombolyticExclusion,
    fields.timeIAInterventionRecommended,
    mapChipFieldOptions(fields.iaDecisionMakingPredefined),
  ],
  [thrombolyticValues.IVAndIAIntervention]: [
    mapToggleFieldOptions(fields.dose),
    fields.timeIVThrombolyticRecommendedMountainTime,
    fields.timeIAInterventionRecommended,
    mapChipFieldOptions(fields.iaDecisionMakingPredefined),
  ],
  [thrombolyticValues.NOTIVThrombolyticOrIAIntervention]: [
    mapChipFieldOptions(fields.thrombolyticIntraarterialExclusionPredefined),
    ...thrombolyticExclusion,
    mapChipFieldOptions(fields.iaExclusionPredefined),
    fields.iaExclusionFreeText,
  ],
  [thrombolyticValues.PossibleIAcandidate]: [
    ...thrombolyticExclusion,
    mapChipFieldOptions(fields.possibleIACandidatePredefined),
    fields.possibleIACandidateFreeText,
  ],
  [thrombolyticValues.IVThrombolyticPossibleIACandidate]: [
    mapToggleFieldOptions(fields.dose),
    mapChipFieldOptions(fields.possibleIACandidatePredefined),
    fields.possibleIACandidateFreeText,
    fields.timeIVThrombolyticRecommendedMountainTime,
  ],
};

export const bloodPressureElements = [
  mapChipFieldOptions(fields.bloodPressureManagementPredefined),
  fields.bloodPressureManagementFreeText,
  mapToggleFieldOptions(fields.targetBloodPressureBelowPredefined),
  mapToggleFieldOptions(fields.targetBloodPressureAbovePredefined),
  mapToggleFieldOptions(fields.targetBloodPressureDiastolicPredefined),
  fields.targetBloodPressureFreeText,
];

export const rightElements = [
  mapChipFieldOptions(
    getOptions4Type(fields.labsPredefined, ServiceTypeEnum.NEURO),
  ),
  fields.labsFreeText,
  mapChipFieldOptions(fields.imagingUrgencySTATPredefined),
  fields.imagingUrgencySTATFreeText,
  mapChipFieldOptions(
    getOptions4Type(
      fields.imagingUrgencyRoutinePredefined,
      ServiceTypeEnum.NEURO,
    ),
  ),
  fields.imagingUrgencyRoutineFreeText,
  mapChipFieldOptions(
    getOptions4Type(
      fields.imagingDiagnosticsUrgencyOutpatientPredefined,
      ServiceTypeEnum.NEURO,
    ),
  ),
  fields.imagingDiagnosticsUrgencyOutpatientFreeText,
  mapChipFieldOptions(
    getOptions4Type(fields.diagnosticTestPredefined, ServiceTypeEnum.NEURO),
  ),
  fields.diagnosticTestFreeText,
  mapChipFieldOptions(
    getOptions4Type(fields.therapyEvaluationPredefined, ServiceTypeEnum.NEURO),
  ),
  fields.therapyEvaluationFreeText,
  mapChipFieldOptions(
    getOptions4Type(fields.medicationsPredefined, ServiceTypeEnum.NEURO),
  ),
  fields.medicationsFreeText,
  mapChipFieldOptions(
    getOptions4Type(fields.vteProphylaxisPredefined, ServiceTypeEnum.NEURO),
  ),
  fields.vteProphylaxisFreeText,
  mapChipFieldOptions(
    fields.thrombolyticAdministrationRecommendationsPredefined,
  ),
  fields.thrombolyticAdministrationRecommendationsFreeText,
  mapChipFieldOptions(fields.hemorrhageReversalPredefined),
  fields.hemorrhageReversalFreeText,
  mapChipFieldOptions(
    getOptions4Type(fields.otherPredefined, ServiceTypeEnum.NEURO),
  ),
  fields.otherFreeText,
  fields.additionalRecommendations,
  mapToggleFieldOptions(
    getOptions4Type(fields.dispositionPredefined, ServiceTypeEnum.NEURO),
  ),
];
