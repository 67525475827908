import invert from 'lodash/invert';

export const ServiceTypeEnum = {
  NEURO: 'Neuro',
  TELE_NEURO: 'TeleNeuroHospital',
  CLINIC: 'Clinic',
  EEG: 'Eeg',
  PEDS_EEG: 'EegPediatric',
};

export const ServiceTypeValues = invert(ServiceTypeEnum);

export const ServiceShortEnum = {
  [ServiceTypeEnum.NEURO]: 'neuro',
  [ServiceTypeEnum.TELE_NEURO]: 'neuro-hospital',
  [ServiceTypeEnum.CLINIC]: 'clinic',
  [ServiceTypeEnum.EEG]: 'eeg',
  [ServiceTypeEnum.PEDS_EEG]: 'eeg-pediatric',
};

export const ShortServiceValues = invert(ServiceShortEnum);

export const ServiceTypeLabels = {
  [ServiceTypeEnum.NEURO]: 'Neuro',
  [ServiceTypeEnum.TELE_NEURO]: 'Tele-NH',
  [ServiceTypeEnum.CLINIC]: 'Clinic',
  [ServiceTypeEnum.EEG]: 'EEG',
  [ServiceTypeEnum.PEDS_EEG]: 'Peds EEG',
};

export const NetworkLabels = {
  [ServiceTypeEnum.NEURO]: 'neuroNetworkName',
  [ServiceTypeEnum.TELE_NEURO]: 'teleNeuroHospitalistNetworkName',
  [ServiceTypeEnum.EEG]: 'eegNetworkName',
  [ServiceTypeEnum.PEDS_EEG]: 'eegPediatricNetworkName',
};

export const ConsultNetworkTypesEnum = {
  [ServiceTypeEnum.NEURO]: 'NeuroNetwork',
  [ServiceTypeEnum.TELE_NEURO]: 'TeleNeuroHospitalistNetwork',
  [ServiceTypeEnum.EEG]: 'EEGNetwork',
  [ServiceTypeEnum.PEDS_EEG]: 'EEGPediatricNetwork',
};

export const UserRoleEnum = {
  ADMINISTRATOR: 'Administrator',
  CUSTOMER: 'Customer',
  NEURO: 'TeleNeurologist',
  TELE_NEURO: 'TeleNeuroHospitalist',
  CLINIC: 'Clinic',
  EEG: 'Eeg',
  PEDS_EEG: 'EegPediatric',
};

export const doctorRoles = [
  UserRoleEnum.NEURO,
  UserRoleEnum.TELE_NEURO,
  UserRoleEnum.CLINIC,
  UserRoleEnum.EEG,
  UserRoleEnum.PEDS_EEG,
];

export const userRolesLabels = {
  [UserRoleEnum.ADMINISTRATOR]: 'Administrator',
  [UserRoleEnum.CLINIC]: 'Clinic',
  [UserRoleEnum.CUSTOMER]: 'Customer',
  [UserRoleEnum.EEG]: 'EEG',
  [UserRoleEnum.PEDS_EEG]: 'Pediatric EEG',
  [UserRoleEnum.NEURO]: 'TeleNeurologist',
  [UserRoleEnum.TELE_NEURO]: 'TeleNeuroHospitalist',
};

export const ConsultType2RoleEnum = {
  [ServiceTypeEnum.NEURO]: UserRoleEnum.NEURO,
  [ServiceTypeEnum.TELE_NEURO]: UserRoleEnum.TELE_NEURO,
  [ServiceTypeEnum.CLINIC]: UserRoleEnum.CLINIC,
  [ServiceTypeEnum.EEG]: UserRoleEnum.EEG,
  [ServiceTypeEnum.PEDS_EEG]: UserRoleEnum.PEDS_EEG,
};

export const ConsultShortType2RoleEnum = {
  [ServiceShortEnum[ServiceTypeEnum.NEURO]]: UserRoleEnum.NEURO,
  [ServiceShortEnum[ServiceTypeEnum.TELE_NEURO]]: UserRoleEnum.TELE_NEURO,
  [ServiceShortEnum[ServiceTypeEnum.CLINIC]]: UserRoleEnum.CLINIC,
  [ServiceShortEnum[ServiceTypeEnum.EEG]]: UserRoleEnum.EEG,
  [ServiceShortEnum[ServiceTypeEnum.PEDS_EEG]]: UserRoleEnum.PEDS_EEG,
};

export const userTitleLabels = {
  MD: 'MD',
  RN: 'RN',
  APN: 'APN',
  DO: 'DO',
  PaC: 'PA-C',
  NP: 'NP',
  MA: 'MA',
  Other: 'Other',
};

export const ConsultRequestStatusEnum = {
  OPEN: 'Open',
  ACCEPTED: 'Accepted',
  CLOSED: 'Closed',
};

export const ConsultRequestPagingStatusEnum = {
  InitialPageSent: 'Initial Page Sent',
  SecondPageSent: 'Second Page Sent',
  ThirdPageSent: 'Third Page Sent',
  BackupPageSent: 'Backup Page Sent to PAOC',
  CallPaoc: '15 Minutes Elapsed, Call PAOC',
};

export const ConsultFilterEnum = {
  OPEN: 'open',
  ALL: 'all',
  ALL_OPEN: 'allOpen',
  ALL_CLOSED: 'allClosed',
  MY_CLOSED: 'myClosed',
  LAST_24: 'last24Hours',
  MY_LAST_24: 'myLast24Hours',
};

export const ConsultFilterLabels = {
  [ConsultFilterEnum.ALL]: 'All',
  [ConsultFilterEnum.ALL_OPEN]: 'All Open',
  [ConsultFilterEnum.ALL_CLOSED]: 'All Closed',
  [ConsultFilterEnum.MY_CLOSED]: 'My Closed',
  [ConsultFilterEnum.LAST_24]: 'Last 24 hours',
  [ConsultFilterEnum.MY_LAST_24]: 'My Last 24 hours',
};

export const ConsultStates = {
  OPEN: 'Open',
  CLOSED: 'Closed',
  CANCELLED: 'Cancelled',
};

export const ConsultFieldTypeEnum = {
  CHIP: 'chip',
  MULTICHIP: 'multichip',
  INPUT: 'input',
  TOGGLE: 'toggle',
  DATETIME: 'datetime',
  DATE: 'date',
  DICTATION: 'dictation',
  SELECT: 'select',
  PHONE: 'phone',
  AUTOCOMPLETE: 'autocomplete',
};

export const IntegrationStatusEnum = {
  NON_INTEGRATED: 'NonIntegrated',
  UNMATCHED: 'Unmatched',
  MATCHED: 'Matched',
};

export const IntegrationTypesEnum = {
  r1: 'R1',
  bi: 'Bidirectional',
};

export const IntegrationStatusLabels = {
  [IntegrationStatusEnum.NON_INTEGRATED]: 'Non-Integrated',
  [IntegrationStatusEnum.UNMATCHED]: 'Unmatched',
  [IntegrationStatusEnum.MATCHED]: 'Matched',
};

export const NeuroConsultTypes = {
  ACUTE_LEVEL1: 'AcuteStrokeZeroFourAndHalfHourStrokeLevel1',
  ACUTE_LEVEL2: 'AcuteStrokeFourAndHalfTwentyFourStrokeLevel2',
  GENERAL: 'GeneralNeurology',
  FOLLOW_UP: 'FollowUpPhoneCall',
};

export const NeuroConsultLabels = {
  [NeuroConsultTypes.ACUTE_LEVEL1]: 'Acute Stroke Level 1 (0-4.5 hrs)',
  [NeuroConsultTypes.ACUTE_LEVEL2]: 'Acute Stroke Level 2 (4.5-24 hrs)',
  [NeuroConsultTypes.GENERAL]: 'General Neurology',
  [NeuroConsultTypes.FOLLOW_UP]: 'Follow-Up Phone Call',
};

export const TeleNeuroConsultLabels = {
  RoutineIPNewConsult: 'Routine IP New consult',
  RoutineIPSubsequentDayFollowUp: 'Routine IP Subsequent day follow-up',
  RoutineIPPhoneQuestion: 'Routine IP phone question',
};

export const EegConsultTypes = {
  ROUTINE: 'Routine',
  PROLONGED: 'Prolonged',
  CEEG: 'CEEG',
  AEEG: 'AEEG',
  CERIBELL: 'Ceribell',
  CERIBELL_CLARITY: 'CeribellWClarityPro',
  ZETO: 'Zeto',
  CADWELL: 'Cadwell',
};

export const EegConsultLabels = {
  [EegConsultTypes.ROUTINE]: 'Routine EEG',
  [EegConsultTypes.PROLONGED]: 'Prolonged EEG',
  [EegConsultTypes.CEEG]: 'Continuous EEG',
  [EegConsultTypes.AEEG]: 'Ambulatory EEG',
  [EegConsultTypes.CERIBELL]: 'Ceribell',
  [EegConsultTypes.ZETO]: 'Zeto',
};

export const BillingStatusEnum = {
  notCompleted: 'NotCompleted',
  hasBillingInfo: 'HasBillingInfo',
  sent: 'Sent',
};

export const BillingStatusLabels = {
  [BillingStatusEnum.notCompleted]: 'Not Completed',
  [BillingStatusEnum.hasBillingInfo]: 'Has Billing Info',
  [BillingStatusEnum.sent]: 'Sent',
};

export const ConsultAttestationEnum = {
  cancelled: 'ConsultCancelled',
  followUpPhoneCall: 'FollowUpPhoneCall',
  duplicate: 'ErrorDuplicate',
  videoConsultEmr: 'VideoConsultNoteInEmr',
  videoConsultTechDifficulty: 'VideoConsultTechDifficultyPastingNote',
  videoConsultFax: 'VideoConsultFaxOnlyFacility',
  phoneConsultNoteEmr: 'PhoneConsultNoteInEmr',
  phoneConsultTechDifficulty: 'PhoneConsultTechDifficultyPastingNote',
  phoneConsultFax: 'PhoneConsultFaxOnlyFacility',
  eegEmr: 'EEGConsultNoteInEMR',
  eegConsultTechnicalDifficulty: 'EEGConsultTechnicalDifficultyPastingNote',
  eegConsultFax: 'EEGConsultFaxOnlyFacility',
  appVideo: 'AppVideoConsult',
  mdVideo: 'MdVideoConsult',
  appAndMDVideo: 'AppAndMdVideoConsult',
  phoneOnly: 'PhoneOnlyConsult',
  hookUp: 'HookUpNoteInEMR',
};

export const ConsultAttestationLabels = {
  [ConsultAttestationEnum.cancelled]: 'Consult Cancelled',
  [ConsultAttestationEnum.followUpPhoneCall]: 'Follow-up phone call',
  [ConsultAttestationEnum.duplicate]: 'Error, duplicate',
  [ConsultAttestationEnum.videoConsultEmr]: 'Video consult, note in EMR',
  [ConsultAttestationEnum.videoConsultTechDifficulty]:
    'Video consult, technical difficulty pasting note',
  [ConsultAttestationEnum.videoConsultFax]: 'Video consult, fax only facility',
  [ConsultAttestationEnum.phoneConsultNoteEmr]: 'Phone consult, note in EMR',
  [ConsultAttestationEnum.phoneConsultTechDifficulty]:
    'Phone consult, technical difficulty pasting note',
  [ConsultAttestationEnum.phoneConsultFax]: 'Phone consult, fax only facility',
  [ConsultAttestationEnum.eegEmr]: 'EEG consult, note in EMR',
  [ConsultAttestationEnum.eegConsultTechnicalDifficulty]:
    'EEG consult, technical difficulty pasting note',
  [ConsultAttestationEnum.eegConsultFax]: 'EEG consult, fax only facility',
  [ConsultAttestationEnum.appVideo]: 'APP video consult',
  [ConsultAttestationEnum.mdVideo]: 'MD video consult',
  [ConsultAttestationEnum.appAndMDVideo]: 'APP and MD video consult',
  [ConsultAttestationEnum.phoneOnly]: 'Phone only consult',
  [ConsultAttestationEnum.hookUp]: 'Hook up, note in EMR',
};

export const ReportTypes = {
  QA: 'QA',
  Volumes: 'Volumes',
  CaseLog: 'CaseLog',
  Metrics: 'Metrics',
  Client: 'Client',
};

export const MatchTypeLabels = {
  Automatched: 'Auto',
  Matched: 'Manual',
  Unmatched: 'Unmatched',
};

export const UNNASIGNED_ITEM = { name: 'Unassigned', id: 'unassigned' };

export const MacroTypes = {
  PERSONAL: 'personal',
  SHARED: 'shared',
};
