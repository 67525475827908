import { mapToggleFieldOptions } from 'features/consults/utils/formFields';

import fields from './formFields';

const NIHSSFields = [
  mapToggleFieldOptions(fields.levelOfConsciousness),
  mapToggleFieldOptions(fields.locQuestions),
  mapToggleFieldOptions(fields.locCommands),
  mapToggleFieldOptions(fields.bestGaze),
  mapToggleFieldOptions(fields.visual),
  mapToggleFieldOptions(fields.facialPalsy),
  mapToggleFieldOptions(fields.motorArmLeft),
  mapToggleFieldOptions(fields.motorArmRight),
  mapToggleFieldOptions(fields.motorLegLeft),
  mapToggleFieldOptions(fields.motorLegRight),
  mapToggleFieldOptions(fields.limbAtaxia),
  mapToggleFieldOptions(fields.sensory),
  mapToggleFieldOptions(fields.bestLanguage),
  mapToggleFieldOptions(fields.dysarthria),
  mapToggleFieldOptions(fields.extinctionAndInattention),
];

const MRSFields = [mapToggleFieldOptions(fields.modifiedRankinScale)];

const ICHFields = [
  mapToggleFieldOptions(fields.ich),
  mapToggleFieldOptions(fields.ichAge),
  mapToggleFieldOptions(fields.ichVolume),
  mapToggleFieldOptions(fields.ichIntraventricularHemorhage),
  mapToggleFieldOptions(fields.ichInfraventricOrigin),
];

const VanFields = [
  mapToggleFieldOptions(fields.vanArmWeakness),
  mapToggleFieldOptions(fields.vanVisualDisturbance),
  mapToggleFieldOptions(fields.vanAphasia),
  mapToggleFieldOptions(fields.vanNeglect),
];

const HuntHessSAHFields = [mapToggleFieldOptions(fields.huntHessSAH)];

export default {
  NIHSS: {
    name: fields.nihssTotal.name,
    label: fields.nihssTotal.label,
    fields: NIHSSFields,
  },
  MRS: {
    name: fields.mrsTotal.name,
    label: fields.mrsTotal.label,
    fields: MRSFields,
  },
  ICH: {
    name: fields.ichTotal.name,
    label: fields.ichTotal.label,
    fields: ICHFields,
  },
  HUNT: {
    name: fields.huntHessTotal.name,
    label: fields.huntHessTotal.name,
    fields: HuntHessSAHFields,
  },
  VAN: {
    name: fields.vanTotal.name,
    label: fields.vanTotal.name,
    fields: VanFields,
  },
};
