import React from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { useUpdateEffect } from 'shared/hooks';
import { MatchTypeLabels } from 'utils/enums';

import Select from 'shared/components/forms/Select/ControlledSelect';
import { FilterElement } from 'features/admin/pages/Consults/ConsultList/ConsultListFilters/ConsultFilters.style';
import HealthSystemSelect from 'shared/components/data/forms/HealthSystemSelect';
import FacilitySelect from 'shared/components/data/forms/FacilitySelect';
import DateTimeFilter from 'features/consults/components/ConsultFilters/DateTimeFilter';
import { FlexBoxAlign } from 'styles/layout';

export const FilterFields = {
  matchType: {
    name: 'matchType',
    placeholder: 'Match Method',
  },
  healthcare: {
    name: 'healthSystemId',
    placeholder: 'Healthcare System',
  },
  facility: {
    name: 'facilityId',
    placeholder: 'Facility',
  },
  fromDate: {
    name: 'FromCreatedAt',
    placeholder: 'From',
  },
  toDate: {
    name: 'ToCreatedAt',
    placeholder: 'To',
  },
};

export const OrderListFilters = ({ filters, handleFilter }) => {
  const { control, watch, reset } = useForm({
    defaultValues: filters,
  });

  useUpdateEffect(() => {
    reset(filters);
  }, [filters]);

  return (
    <FlexBoxAlign flexWrap="wrap">
      <FilterElement>
        <DateTimeFilter
          {...FilterFields.fromDate}
          control={control}
          handleFilter={handleFilter}
        />
      </FilterElement>
      <FilterElement>
        <DateTimeFilter
          {...FilterFields.toDate}
          control={control}
          handleFilter={handleFilter}
        />
      </FilterElement>
      <FilterElement>
        <Select
          {...FilterFields.matchType}
          control={control}
          options={Object.entries(MatchTypeLabels).map(([value, text]) => ({
            value,
            text,
          }))}
          handleChange={({ target }) => {
            handleFilter({ [target.name]: target.value });
          }}
        />
      </FilterElement>
      <FilterElement>
        <HealthSystemSelect
          {...FilterFields.healthcare}
          control={control}
          handleChange={(value) => {
            handleFilter({
              [FilterFields.healthcare.name]: value,
              [FilterFields.facility.name]: null,
            });
          }}
        />
      </FilterElement>
      <FilterElement>
        <FacilitySelect
          {...FilterFields.facility}
          control={control}
          healthSystem={watch(FilterFields.healthcare.name)}
          requiredSystem
          handleChange={(value) =>
            handleFilter({ [FilterFields.facility.name]: value })
          }
        />
      </FilterElement>
    </FlexBoxAlign>
  );
};

OrderListFilters.propTypes = {
  filters: PropTypes.shape({}).isRequired,
  handleFilter: PropTypes.func.isRequired,
};
