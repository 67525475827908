import {
  ServiceTypeEnum,
  ConsultStates,
  NeuroConsultLabels,
  TeleNeuroConsultLabels,
  EegConsultLabels,
  ShortServiceValues,
} from 'utils/enums';
import { ConsultIntegrationOptions } from 'features/consults/utils/constants';
import demographicsFields, {
  observationOptions,
} from 'features/consults/shared/tabs/Demographics/formFields';
import {
  attestationOfNeuroOptions,
  attestationOfEEGOptions,
} from 'features/consults/shared/tabs/Signature/formFields';
import { thrombolyticOptions } from 'features/consults/shared/tabs/Plan/formFields';
import { mapStringIdToNumber, mapOptionsToSelect } from 'utils/mappers';

const statusOptions = Object.values(ConsultStates).reduce(
  (res, value) => [...res, { text: value, value }],
  [],
);

export const mapAdminConsultFilters = ({
  query,
  networkId,
  healthcareSystemId,
  facilityId,
  ...otherFilters
}) => ({
  ...otherFilters,
  query: query || '',
  networkId: mapStringIdToNumber(networkId),
  healthcareSystemId: mapStringIdToNumber(healthcareSystemId),
  facilityId: mapStringIdToNumber(facilityId),
});

export const filterTypes = {
  DATETIME: 'datetime',
  SELECT: 'select',
  PROVIDER: 'provider',
  HEALTHSYSTEM: 'healthSystem',
  FACILITY: 'facility',
  NETWORK: 'network',
  MULTISELECT: 'multiselect',
};

const yesNoOptions = [
  { value: 'true', text: 'Yes' },
  { value: 'false', text: 'No' },
];

const fromCreatedAtFilter = {
  filterType: filterTypes.DATETIME,
  name: 'fromCreatedAt',
  placeholder: 'From',
  disableFuture: true,
};

const toCreatedAtFilter = {
  filterType: filterTypes.DATETIME,
  name: 'toCreatedAt',
  placeholder: 'To',
  disableFuture: true,
};

const neuroConsultTypeFilter = {
  filterType: filterTypes.MULTISELECT,
  name: 'neuroConsultTypes',
  placeholder: 'Consult Type',
  options: Object.entries(NeuroConsultLabels).map(([id, name]) => ({
    id,
    name,
  })),
};

const patientLocationTypeFilter = {
  filterType: filterTypes.SELECT,
  name: 'patientLocationType',
  placeholder: 'Patient Location',
  options: mapOptionsToSelect(demographicsFields.neuroPatientLocation.options),
};

const withQualityFlagSelectedFilter = {
  filterType: filterTypes.SELECT,
  name: 'withQualityFlagSelected',
  placeholder: 'Quality Flag',
  options: yesNoOptions,
};

const providerFilter = {
  filterType: filterTypes.PROVIDER,
  name: 'assignToUserIds',
  placeholder: 'Neurologist',
};

const healhSystemFilter = {
  filterType: filterTypes.HEALTHSYSTEM,
  name: 'healthcareSystemId',
  placeholder: 'Healthcare System',
};

const facilityFilter = {
  filterType: filterTypes.FACILITY,
  name: 'facilityId',
  placeholder: 'Facility',
};

const networkFilter = {
  filterType: filterTypes.NETWORK,
  name: 'networkId',
  placeholder: 'Network',
};

const attestationNeuroFilter = {
  filterType: filterTypes.MULTISELECT,
  name: 'neuroAttestationOfConsultCompletionTypes',
  placeholder: 'Attestation',
  options: attestationOfNeuroOptions.map(({ label, value }) => ({
    id: value,
    name: label,
  })),
};

const thrombolyticInterventionFilter = {
  filterType: filterTypes.MULTISELECT,
  name: 'planThrombolyticInterventionTypes',
  placeholder: 'Thrombolytic / Intervention',
  options: thrombolyticOptions.map(({ label, value }) => ({
    id: value,
    name: label,
  })),
};

const teleNeuroTypeFilter = {
  filterType: filterTypes.SELECT,
  name: 'teleNeuroHospitalConsultTypes',
  placeholder: 'Consult Type',
  options: Object.entries(TeleNeuroConsultLabels).map(([key, label]) => ({
    value: key,
    text: label,
  })),
};

const eEGTypeFilter = {
  filterType: filterTypes.SELECT,
  name: 'eegConsultType',
  placeholder: 'EEG Type',
  options: Object.entries(EegConsultLabels).map(([key, label]) => ({
    value: key,
    text: label,
  })),
};

const eEGReadConsultObservationFilter = {
  filterType: filterTypes.SELECT,
  name: 'eegReadConsultObservationType',
  placeholder: 'cEEG Type',
  options: mapOptionsToSelect(observationOptions),
};

const attestationEEGFilter = {
  filterType: filterTypes.MULTISELECT,
  name: 'eegAttestationOfEegCompletionTypes',
  placeholder: 'Attestation',
  options: attestationOfEEGOptions.map(({ label, value }) => ({
    id: value,
    name: label,
  })),
};

const withEegStatField = {
  filterType: filterTypes.SELECT,
  name: 'withEegStatOptionsTypeSelected',
  placeholder: 'Stat Read',
  options: yesNoOptions,
};

const statusFilter = {
  filterType: filterTypes.SELECT,
  name: 'state',
  placeholder: 'Status',
  options: statusOptions,
};

const integrationStatusFilter = {
  filterType: filterTypes.SELECT,
  name: 'integrationStatusList',
  placeholder: 'Integration Status',
  options: ConsultIntegrationOptions,
};

const filterData = {
  [ServiceTypeEnum.NEURO]: [
    fromCreatedAtFilter,
    toCreatedAtFilter,
    neuroConsultTypeFilter,
    patientLocationTypeFilter,
    withQualityFlagSelectedFilter,
    providerFilter,
    healhSystemFilter,
    facilityFilter,
    networkFilter,
    attestationNeuroFilter,
    thrombolyticInterventionFilter,
    statusFilter,
    integrationStatusFilter,
  ],
  [ServiceTypeEnum.TELE_NEURO]: [
    fromCreatedAtFilter,
    toCreatedAtFilter,
    teleNeuroTypeFilter,
    patientLocationTypeFilter,
    providerFilter,
    healhSystemFilter,
    facilityFilter,
    networkFilter,
    statusFilter,
  ],
  [ServiceTypeEnum.CLINIC]: [
    fromCreatedAtFilter,
    toCreatedAtFilter,
    providerFilter,
    statusFilter,
  ],
  [ServiceTypeEnum.EEG]: [
    fromCreatedAtFilter,
    toCreatedAtFilter,
    eEGTypeFilter,
    eEGReadConsultObservationFilter,
    providerFilter,
    healhSystemFilter,
    facilityFilter,
    networkFilter,
    attestationEEGFilter,
    withEegStatField,
    statusFilter,
  ],
  [ServiceTypeEnum.PEDS_EEG]: [
    fromCreatedAtFilter,
    toCreatedAtFilter,
    eEGTypeFilter,
    eEGReadConsultObservationFilter,
    providerFilter,
    healhSystemFilter,
    facilityFilter,
    networkFilter,
    attestationEEGFilter,
    withEegStatField,
    statusFilter,
  ],
};

export const serviceFilters = (service) =>
  filterData[ShortServiceValues[service]];
