import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { appStateActions } from 'store/appStateSlice';
import { adminActions } from 'features/admin/store/slice';
import {
  useMountEffect,
  useAssets,
  usePerformActionOnSuccess,
  useEditMode,
} from 'shared/hooks';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import { isFile } from 'utils/helpers';

const useUserEdit = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { user } = useSelector(({ admin }) => admin);
  const editMode = useEditMode();
  const { assetUrl, fetchAssets, uploadAsset } = useAssets();

  usePerformActionOnSuccess(SuccessActionTypesEnum.USER_UPDATED, () => {
    dispatch(appStateActions.hideSnackbar());
    editMode.disableEditMode();
  });

  const fetchUser = useCallback(() => {
    dispatch(adminActions.getUserById(userId));
  }, [dispatch, userId]);

  useMountEffect(() => {
    fetchAssets([userId]);
  });

  const onUserUpdate = async ({ userImage, ...data }) => {
    if (isFile(userImage)) {
      try {
        await uploadAsset(userId, userImage);
      } catch (_e) {
        return;
      }
    }
    dispatch(
      adminActions.updateUser({
        id: Number(userId),
        ...data,
      }),
    );
  };

  const userData = useMemo(
    () =>
      Number(userId) === user?.id
        ? {
            ...user,
            userImage: user?.userImage ?? assetUrl(userId),
          }
        : {},
    [userId, user, assetUrl],
  );

  return {
    userId: Number(userId),
    userData,
    fetchUser,
    onUserUpdate,
    ...editMode,
  };
};

export default useUserEdit;
